import React from 'react'
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import { SVGIcon } from '../../wrappers/SVGIcon';

import { Icons } from '../../../data/Assets';
import { ImageAsset } from '../../wrappers/ImageAsset';

interface IPreviewPanelProps {
    linked: boolean;
    fileName: string;
    src: string;
    onDownloadFile: () => void;
    onRemoveFile: () => void;
    onMinimizePreview: () => void;
}

const PreviewPanel = (props: IPreviewPanelProps) => {
    let { linked, fileName, src, onDownloadFile, onRemoveFile, onMinimizePreview } = props;

    if (fileName?.trim()?.length)
        fileName = fileName.split('_')[1].split('_')[0];

    return (
        <div className='immployer__details_form_inputs__container__preview_panel'>
            <div className='preview__file_details__container'>
                <h5 className='preview__file_name'>{fileName}</h5>

                <div className='preview__file_actions'>
                    <button className='preview__file_minimize_btn' onClick={onMinimizePreview}>
                        <SVGIcon src={Icons.Minimize} />
                    </button>
                    <button className='preview__file_download_btn' onClick={onDownloadFile}>
                        <SVGIcon src={Icons.DocDownload} />
                    </button>
                    {!linked && <button className='preview__file_delete_btn' onClick={onRemoveFile}>
                        <SVGIcon src={Icons.Trash2Fill} />
                    </button>}
                </div>
            </div>

            {src &&
                <>
                    <TransformWrapper>
                        <span className=''>Scroll or expand to zoom</span>
                        <TransformComponent>
                            <ImageAsset className='preview__file_image' src={src} alt={fileName} />
                        </TransformComponent>
                    </TransformWrapper>
                </>
            }
        </div>
    )
}

export default PreviewPanel;