import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { Icons } from "../../data/Assets";

import { openModal } from "../../store/slices/modal";

import useSignOut from "../../hooks/auth/useSignOut";

import { SVGIcon } from "../wrappers/SVGIcon";

interface INavItem {
    title: string;
    icon: string;
    link?: string;
    clickFn?: () => void;
};

const Sidebar = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();

    const TopNav: INavItem[] = [
        { title: "Home", icon: Icons.HomeFill, link: "/" },
        { title: "Employers", icon: Icons.BriefcaseFill, link: "/employers" },
        { title: "Foreign Workers", icon: Icons.Users, link: "/foreign-workers" },
        { title: "Documents", icon: Icons.Document, link: "/documents" },
        { title: "Assessment", icon: Icons.UserCard2, link: "/assessments" },
        { title: "Hiring", icon: Icons.Document3, link: "/hiring" },
        { title: "Help", icon: Icons.QuestionChatFill, link: "/help" },
    ];

    const BottomNav: INavItem[] = [
        { title: "Log Out", icon: Icons.LogOff, clickFn: () => openLogoutModal() },
    ];

    const { mutate: signOut } = useSignOut();

    const openLogoutModal = () => {
        dispatch(openModal({
            name: 'MESSAGE_MODAL',
            data: {
                view: 'CONFIRMATION',
                closeBtn: false,
                title: "Confirm sign out?",
                content: "Do you want to sign out of your account?",
                actionTxt: "Sign out",
                action: () => signOut(),
            }
        }))
    };

    const handleNavClick = (item: INavItem) => {
        if (item.clickFn) item.clickFn();
        if (item.link && location.pathname !== item.link) navigate(item.link);
    };

    const getActiveLink = (link: string | undefined) => {
        if (!link) return false;

        if (link === "/foreign-workers") return location.pathname === link || location.pathname.includes('/foreign-workers/');
        if (link === "/employers") return location.pathname === link || location.pathname.includes('/employers/');

        return location.pathname === link;
    };

    return (
        <div className="immployer__sidebar">
            <div className="immployer__sidebar__top_menu">
                {TopNav.map((item, index) => {
                    const isOptionActive = getActiveLink(item.link);

                    return (
                        <button
                            key={index}
                            onClick={() => handleNavClick(item)}
                            className={`immployer__sidebar__menu__btn ${isOptionActive ? "immployer__sidebar__menu__btn__active" : ""}`}
                        >
                            <SVGIcon src={item.icon} color={isOptionActive ? 'primary' : 'grey'} />
                            <span>{item.title}</span>
                        </button>
                    )
                })}
            </div>
            <div className="immployer__sidebar__bottom_menu">
                {BottomNav.map((item, index) => {
                    const isOptionActive = getActiveLink(item.link);

                    return (
                        <button
                            key={index}
                            onClick={() => handleNavClick(item)}
                            className={`immployer__sidebar__menu__btn ${isOptionActive ? "immployer__sidebar__menu__btn__active" : ""}`}
                        >
                            <SVGIcon src={item.icon} color={isOptionActive ? 'primary' : 'grey'} />
                            <span>{item.title}</span>
                        </button>
                    )
                })}
            </div>
        </div>
    );
};

export default Sidebar;