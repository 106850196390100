import React, { useEffect, useRef } from 'react'
import { CancelTokenSource } from 'axios';

import { IDocument } from '../../../interfaces/entities';

import { SVGIcon } from '../../wrappers/SVGIcon';

import { Icons } from '../../../data/Assets';

interface IUploadBtnProps {
    docType: string;
    document: IDocument | null;
    isProcessing: boolean;
    apiError: string | null;
    linked: boolean;
    onFileChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onRemoveFile: () => void;
    onPreviewFile: () => void;
    cancelParse: CancelTokenSource | null;
}

const UploadFileBtn = (props: IUploadBtnProps) => {
    const { docType, document, isProcessing, cancelParse, apiError, linked, onFileChange, onRemoveFile, onPreviewFile } = props;

    const fileRef = useRef<HTMLInputElement>(null);
    let fileName = document?.original || '';

    if (fileName?.trim()?.length)
        fileName = fileName.split('_')[1].split('_')[0];

    const onCancelAutoFill = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        if (!cancelParse) return;

        if (fileRef.current) fileRef.current.value = '';

        cancelParse.cancel('Cancel autofill');
    }

    useEffect(() => {
        if (!document && fileRef.current) {
            fileRef.current.value = '';
        }
    }, [document]); // eslint-disable-line

    return (
        <div className="immployer__details_file_upload__view">
            <input ref={fileRef} type="file" accept="image/png,image/jpg,image/jpeg,application/pdf" onChange={(e) => onFileChange(e)} hidden />
            {isProcessing &&
                <>
                    <div className='immployer__details_file_analyze__processing'>
                        <i className='immployer__btn_loader' />
                        <span className='file_analyze__processing__text'>Analyzing document...</span>
                    </div>
                    <button
                        className={`immployer__btn immployer__btn__secondary ${linked ? "immployer__btn__secondary__disabled" : ""}`}
                        onClick={onCancelAutoFill}
                    >
                        Cancel Autofill
                    </button>
                </>
            }

            {!document && !isProcessing &&
                <div className="immployer__details_file_upload__btn">
                    <button
                        className={`immployer__btn immployer__btn__secondary ${linked ? "immployer__btn__secondary__disabled" : ""}`}
                        onClick={(e) => { e.preventDefault(); if (!linked) fileRef.current?.click(); }}
                    >
                        Upload {docType.toLowerCase()}
                    </button>
                    {apiError && <span className='immployer__details_file_analyze__error'>{apiError}</span>}
                </div>
            }

            {document && !isProcessing && !apiError &&
                <div className="immployer__details_file_uploaded__view">
                    <h5 className="color_dark">{docType} upload:</h5>
                    <div className="immployer__details_file_uploaded__actions">
                        <button onClick={() => onPreviewFile()}>
                            <SVGIcon src={Icons.EyeFill} />
                            <span>{fileName}</span>
                        </button>
                        {!linked && <button onClick={() => onRemoveFile()}><SVGIcon src={Icons.X} /></button>}
                    </div>
                </div>
            }
        </div >
    )
}

export default UploadFileBtn;