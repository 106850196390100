import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import ReactModal from "react-modal";

import { getModalData, isModalActive, closeModal } from "../../store/slices/modal";

import { IFilterModalData } from "../../interfaces/modals";

import { SVGIcon } from "../wrappers/SVGIcon";

import { Icons } from "../../data/Assets";

const modalName = "FILTER_MODAL";
const modalTitle = "Filter";
const modalClass = "filter";

ReactModal.setAppElement('#root');
const FilterModal = () => {
    const dispatch = useDispatch();

    const isModalVisible: boolean = useSelector(isModalActive(modalName));
    const modalData: IFilterModalData | null = useSelector(getModalData(modalName));
    const { closeOnEsc = true, closeOnOutsideClick = true, closeBtn = true, filterText, filters, selectedFilters, setSelectedFilters } = modalData || {};

    const afterOpenModal = () => { };

    const onCloseModal = () => {
        dispatch(closeModal(modalName));
    };

    const [tagIds, setTagIds] = useState<number[]>(selectedFilters?.map((tag) => tag.id) || []);

    useEffect(() => {
        setTagIds(selectedFilters?.map((tag) => tag.id) || []);
    }, [selectedFilters]); // eslint-disable-line

    const onClearFilters = () => {
        if (setSelectedFilters)
            setSelectedFilters([]);
        onCloseModal();
    }

    const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        if (e.target.checked) {
            setTagIds([...tagIds, parseInt(value)]);
        } else {
            setTagIds(tagIds.filter((id) => id !== parseInt(value)));
        }
    };

    const onApplyFilter = () => {
        const selectedTags = filters?.filter((filter) => tagIds.includes(filter.id));
        if (setSelectedFilters)
            setSelectedFilters(selectedTags || []);
        onCloseModal();
    };

    return (
        <ReactModal
            id={modalName}
            isOpen={isModalVisible && !!modalData}
            onAfterOpen={afterOpenModal}
            onRequestClose={onCloseModal}
            contentLabel={modalTitle}
            overlayClassName={`immployer__modal_overlay immployer__modal_overlay__${modalClass}`}
            className={`immployer__modal_dialog immployer__modal_dialog__${modalClass}`}
            shouldCloseOnEsc={closeOnEsc}
            shouldCloseOnOverlayClick={closeOnOutsideClick}
        >
            <div className={`immployer__modal_content immployer__modal_content__${modalClass}`}>
                {closeBtn &&
                    <button className="immployer__modal_close_btn" onClick={onCloseModal}>
                        <SVGIcon src={Icons.X} />
                    </button>
                }
                <div className="immployer__filter_modal__filter_view">
                    <br />
                    <div className="header">
                        <h4 className="color_dark">{filterText}</h4>
                        <button onClick={onClearFilters}>Clear Filters</button>
                    </div>
                    <br />
                    <div className="immployer__filter_modal__filter_content">
                        {filters?.map((filter, index) => (
                            <div key={index} className="immployer__filter_modal__filter_item">
                                <label className={`immployer__filter_custom_checkbox`}>
                                    <input
                                        className={`immployer__filter_checkbox`}
                                        type="checkbox"
                                        id={`filter_${index}`}
                                        name={`filter_${index}`} value={filter.id}
                                        checked={tagIds.includes(filter.id)}
                                        onChange={onFilterChange}
                                    />
                                    <span className={`immployer__filter_checkmark`}>
                                        {tagIds.includes(filter.id) ? <SVGIcon src={Icons.Checkmark} /> : null}
                                    </span>
                                </label>
                                <label className={`immployer__filter_label`} htmlFor={`filter_${index}`}>{filter.name}</label>
                            </div>
                        ))}
                    </div>

                    <br />
                    <div className="immployer__filter_modal__filter_actions">
                        {/* <button className="immployer__btn immployer__btn__secondary" onClick={onClose}>Cancel</button> */}
                        <button
                            className={`immployer__btn immployer__btn__primary`}
                            onClick={onApplyFilter}
                        >
                            Apply Filter
                        </button>
                    </div>
                </div>
            </div>
        </ReactModal>
    );
};

export default FilterModal;