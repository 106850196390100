import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { IColumnProps, ILMIAsListTableProps } from "../../../interfaces/tables";

import { Icons } from "../../../data/Assets";

import { getCanadianDollarsString, getDateString, getElipsisString, getFormattedString } from "../../../helpers/utility";

import { SVGIcon } from "../../wrappers/SVGIcon";

const LMIAsListTable = (props: ILMIAsListTableProps) => {
    const { isLoading, lmias, sortQuery, setSortQuery } = props;

    const { employer_id } = useParams();
    const navigate = useNavigate();

    const columns: IColumnProps[] = [
        { name: "LMIA Number", sort: true, identifier: 'lmia_number', buttonClass: 'btn_block' },
        { name: "Category", sort: true, identifier: 'category', buttonClass: 'btn_block' },
        { name: "Status", sort: true, identifier: 'status', buttonClass: 'btn_block' },
        { name: "Issue Date", sort: true, identifier: 'issue_date', buttonClass: 'btn_block' },
        { name: "Expiration", sort: true, identifier: 'expiration_date', buttonClass: 'btn_block' },
        { name: "Job Title", sort: true, identifier: 'title', buttonClass: 'btn_block' },
        { name: "Wage", sort: true, identifier: 'wage', buttonClass: 'btn_block' },
        { name: "Location", sort: true, identifier: 'location', buttonClass: 'btn_block' },
        { name: "Positions", sort: true, identifier: 'positions', buttonClass: 'btn_block' },
        { name: "Unassigned", buttonClass: 'btn_block' },
    ];

    const handleSort = (sort_key: string) => {
        setSortQuery({
            sort_key,
            sort_order: sortQuery.sort_order === 'ASC' ? 'DESC' : 'ASC'
        });
    }

    return (
        <div className="immployer__list_table__container">
            <table className="immployer__list_table">
                <thead>
                    <tr>
                        {columns.map((column, index) =>
                            <th key={index}>
                                <div>
                                    <button onClick={() => column?.sort ? handleSort(column.identifier ?? 'lmia_number') : null} className={`${column.buttonClass ?? ''}`}>
                                        {column.name}
                                        {column?.sort && column.identifier === sortQuery.sort_key && <SVGIcon src={Icons.ArrowDownUp} color='primary' />}
                                    </button>
                                </div>
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {
                        isLoading
                            ? <tr>
                                <td colSpan={columns.length}>
                                    <div className="display_flex flex_dir_column display_flex_center">
                                        <span className="wt_600 pb_10">Loading</span>
                                        <i className='immployer__list_loader' />
                                    </div>
                                </td>
                            </tr>
                            : !lmias?.length
                                ? <tr>
                                    <td colSpan={columns.length} className='text_center'>
                                        No records found.
                                    </td>
                                </tr>
                                : lmias.map((lmia, index) =>
                                    <tr
                                        key={index}
                                        onClick={() => navigate(`/employers/${employer_id}/lmias/${lmia.id}/details`)}
                                    >
                                        <td>{lmia.lmia_number}</td>
                                        <td>{getFormattedString(lmia.lmia_type.category, 'title_case')}</td>
                                        <td>{getFormattedString(lmia.status, 'title_case')}</td>
                                        <td>{lmia.issue_date ? getDateString(lmia.issue_date, "MMM DD-YYYY") : "N/A"}</td>
                                        <td>{lmia.expiration_date ? getDateString(lmia.expiration_date, "MMM DD-YYYY") : "N/A"}</td>
                                        <td>{lmia.noc} - {getElipsisString(lmia.title, 20)}</td>
                                        <td>${getCanadianDollarsString(lmia.wage.toString())}/{lmia.wage_type === 'hourly' ? 'hr' : 'yr'}</td>
                                        <td>{lmia.location.identifier}</td>
                                        <td>{lmia.positions}</td>
                                        <td>{lmia.positions - lmia.lmia_permits.length}</td>
                                    </tr>
                                )
                    }
                </tbody>
            </table>
        </div>
    );
};

export default LMIAsListTable;