import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import moment from "moment";

import { CalenderInput, SearchSelect, SelectDropdown, TextInput } from "../../reusables/FormElements";

import { ILMIADetailsProps, LMIAField } from "../../../interfaces/lmia";
import { ISelectDropdownOption } from "../../../interfaces/formElements";
import { TLMIACategory, TLMIAStatus, TWageType } from "../../../interfaces/entities.types";
import { IJobTitle, ILMIAType, ILocation } from "../../../interfaces/entities";
import { ISortQuery } from "../../../interfaces/services";

import useSaveLMIA from "../../../hooks/lmias/useSaveLMIA";
import useGetLocations from "../../../hooks/locations/useGetLocations";
import useGetJobTitles from "../../../hooks/jobs/useGetJobTitles";
import useGetLMIATypes from "../../../hooks/lmia_types/useGetLMIATypes";

import { OPLMIACategories, OPLMIAStatuses, OPWageTypes } from "../../../data/options";

import { getCanadianDollarsString, getDateString } from "../../../helpers/utility";

const LMIADetailsFormView = (props: ILMIADetailsProps) => {
    const { employer_id } = useParams();
    const navigate = useNavigate();

    const { operation, isLoading, lmiaData, formData, setFormData } = props;

    const [requiredFields, setRequiredFields] = useState<LMIAField[]>(['location', 'lmia_number', 'type', 'category', 'jobTitle', 'issueDate', 'expiryDate', 'wage', 'wageType', 'positions', 'status']);
    const selectFields: LMIAField[] = ['location', 'type', 'category', 'jobTitle', 'wageType', 'status'];

    const sortQuery: ISortQuery = { sort_key: 'identifier', sort_order: 'ASC' };

    const [lmiaTypes, setLMIATypes] = useState<ILMIAType[]>([]);

    const [isProcessing, setIsProcessing] = useState(false);

    const { data: locations, isLoading: isLocationsLoading } = useGetLocations('employer', employer_id, sortQuery);
    const { data: jobTitles, isLoading: isJobTitlesLoading } = useGetJobTitles();
    const { data: lmiaTypesRes, isLoading: isLMIATypesLoading } = useGetLMIATypes();
    const { mutate: saveLMIA } = useSaveLMIA();

    useEffect(() => {
        if (lmiaData) {
            const jobTitle: IJobTitle | undefined = jobTitles?.find((jobTitle: IJobTitle) => jobTitle.job_title === lmiaData.title);
            const location: ILocation | undefined = locations?.find((location: ILocation) => location.id === lmiaData.location_id);
            const category = OPLMIACategories.find(category => category.value === lmiaData.lmia_type.category);
            const status = OPLMIAStatuses.find(status => status.value === lmiaData.status);
            const wageType = OPWageTypes.find(wageType => wageType.value === lmiaData.wage_type);
            const wage = lmiaData.wage ? getCanadianDollarsString(lmiaData.wage.toString()) : "";
            const submissionDate = lmiaData.submission_date ? getDateString(lmiaData.submission_date) : null;
            const issueDate = lmiaData.issue_date ? getDateString(lmiaData.issue_date) : null;
            const expiryDate = lmiaData.expiration_date ? getDateString(lmiaData.expiration_date) : null;

            let lmiaTypesResFiltered: ILMIAType[] | undefined = undefined;
            if (category?.value === 'standard') {
                lmiaTypesResFiltered = lmiaTypesRes?.filter(type => type.category === 'standard');
            } else if (category?.value === 'exempt') {
                lmiaTypesResFiltered = lmiaTypesRes?.filter(type => type.category === 'exempt');
            };
            setLMIATypes(lmiaTypesResFiltered ?? []);
            const type: ILMIAType | undefined = lmiaTypesResFiltered?.find(type => type.id === lmiaData.lmia_type_id)

            setFormData({
                id: lmiaData.id,
                location: { value: { value: location?.id.toString() ?? '', label: location?.identifier ?? '', }, error: "" },
                lmia_number: { value: lmiaData.lmia_number, error: "" },
                type: { value: { value: type?.id?.toString() ?? '', label: type?.type ?? '' }, error: "" },
                category: { value: { value: category?.value ?? '', label: category?.label ?? '' }, error: "" },
                jobTitle: { value: { value: jobTitle?.job_title ?? '', label: jobTitle ? `${jobTitle.noc_code.noc_code} - ${jobTitle.job_title}` : '' }, error: "" },
                submissionDate: { value: submissionDate ?? '', error: "" },
                issueDate: { value: issueDate ?? '', error: "" },
                expiryDate: { value: expiryDate ?? '', error: "" },
                wage: { value: wage, error: "" },
                wageType: { value: { value: wageType?.value ?? '', label: wageType?.label ?? '' }, error: "" },
                positions: { value: lmiaData.positions.toString(), error: "" },
                status: { value: { value: status?.value ?? '', label: status?.label ?? '' }, error: "" },
            });
        };

        // eslint-disable-next-line
    }, [lmiaData, jobTitles, locations, lmiaTypesRes])

    useEffect(() => {
        if (formData.status.value.value === 'approved') {
            setRequiredFields(prevData => {
                const newFields = [...prevData];
                if (!newFields.includes('issueDate')) newFields.push('issueDate');
                if (!newFields.includes('expiryDate')) newFields.push('expiryDate');
                return newFields;
            });
        } else {
            setRequiredFields(prevData => {
                const newFields = [...prevData];
                if (newFields.includes('issueDate')) newFields.splice(newFields.indexOf('issueDate'), 1);
                if (newFields.includes('expiryDate')) newFields.splice(newFields.indexOf('expiryDate'), 1);
                return newFields;
            });
        };

        // eslint-disable-next-line
    }, [formData]);

    const onChangeText = (name: LMIAField, value: string) => {
        setFormData({
            ...formData,
            [name]: { value, error: "" }
        });
    };

    const onChangeSelect = (name: LMIAField, option: ISelectDropdownOption) => {
        setFormData(prevData => {
            return {
                ...prevData,
                [name]: { value: option, error: "" }
            };
        });

        if (name === 'category') {
            if (option.value === 'standard') {
                const lmiaTypesResFiltered = lmiaTypesRes?.filter(type => type.category === 'standard');
                setLMIATypes(lmiaTypesResFiltered ?? []);

                const lmiaType = lmiaTypesRes?.find(type => type.id === Number(formData.type.value.value));
                if (lmiaType?.category !== 'standard') {
                    setFormData(prevData => {
                        return {
                            ...prevData,
                            type: { value: { value: '', label: '' }, error: '' }
                        };
                    });
                };
            } else if (option.value === 'exempt') {
                const lmiaTypesResFiltered = lmiaTypesRes?.filter(type => type.category === 'exempt');
                setLMIATypes(lmiaTypesResFiltered ?? []);

                const lmiaType = lmiaTypesRes?.find(type => type.id === Number(formData.type.value.value));
                if (lmiaType?.category !== 'exempt') {
                    setFormData(prevData => {
                        return {
                            ...prevData,
                            type: { value: { value: '', label: '' }, error: '' }
                        };
                    });
                };
            };
        };
    };

    const isDisabled = () => {
        const emptyFields = requiredFields.some(field => {
            let value = "";
            if (selectFields.includes(field)) {
                const dataValue = formData[field].value as ISelectDropdownOption;
                value = dataValue.value;
            } else {
                value = formData[field].value as string;
            };
            return !value?.trim()?.length;
        });

        const submissionDate = lmiaData?.submission_date ? getDateString(lmiaData.submission_date) : null;
        const issueDate = lmiaData?.issue_date ? getDateString(lmiaData.issue_date) : null;
        const expiryDate = lmiaData?.expiration_date ? getDateString(lmiaData.expiration_date) : null;

        const isDataSame =
            formData.location.value.value === (lmiaData?.location_id?.toString() ?? '') &&
            formData.lmia_number.value === (lmiaData?.lmia_number ?? '') &&
            formData.type.value.value === (lmiaData?.lmia_type_id?.toString() ?? '') &&
            formData.category.value.value === (lmiaData?.lmia_type?.category ?? '') &&
            formData.jobTitle.value.value === (lmiaData?.title ?? '') &&
            formData.submissionDate.value === (submissionDate ?? '') &&
            formData.issueDate.value === (issueDate ?? '') &&
            formData.expiryDate.value === (expiryDate ?? '') &&
            formData.wage.value.replace(/,/g, '') === (lmiaData?.wage?.toString() ?? '') &&
            formData.wageType.value.value === (lmiaData?.wage_type ?? '') &&
            formData.positions.value === (lmiaData?.positions?.toString() ?? '') &&
            formData.status.value.value === (lmiaData?.status ?? '');

        return !employer_id || emptyFields || isDataSame || isProcessing || isLoading;
    };

    const onSaveData = () => {
        if (isDisabled()) return;

        // clear all errors
        setFormData(prevData => {
            const newData = { ...prevData };
            Object.keys(newData).forEach(key => {
                if (key === "id") return;
                newData[key as LMIAField].error = "";
            });
            return newData;
        });

        setIsProcessing(true);

        const jobTitle = jobTitles?.find(jobTitle => jobTitle.job_title === formData.jobTitle.value.value);
        if (!jobTitle) {
            setIsProcessing(false);
            return;
        };

        saveLMIA({
            employer_id: Number(employer_id),
            payload: {
                id: formData.id,
                location_id: Number(formData.location.value.value),
                lmia_number: formData.lmia_number.value,
                lmia_type_id: Number(formData.type.value.value),
                category: formData.category.value.value as TLMIACategory,
                title: formData.jobTitle.value.value,
                noc: jobTitle.noc_code.noc_code,
                submission_date: formData.status.value.value === 'pending' && formData.submissionDate.value?.length ? moment.utc(formData.submissionDate.value).toDate().toISOString() : null,
                issue_date: formData.status.value.value === 'approved' && formData.issueDate.value?.length ? moment.utc(formData.issueDate.value).toDate().toISOString() : null,
                expiration_date: formData.status.value.value === 'approved' && formData.expiryDate.value?.length ? moment.utc(formData.expiryDate.value).toDate().toISOString() : null,
                wage: Number(formData.wage.value.replace(/,/g, '')),
                wage_type: formData.wageType.value.value as TWageType,
                positions: Number(formData.positions.value),
                status: formData.status.value.value as TLMIAStatus,
            },
            operation,
            setIsProcessing,
        });
    };

    return (
        <div className='immployer__details_form_view'>
            <div className="immployer__details_form_inputs__container">
                <div className="immployer__details_form_inputs">
                    <SelectDropdown
                        name="category"
                        label="LMIA Category"
                        required={true}
                        placeholder="-- select lmia category --"
                        value={formData.category.value}
                        onChange={(option: ISelectDropdownOption) => onChangeSelect("category", option)}
                        errorMsg={formData.category.error}
                        options={OPLMIACategories}
                        read_only={isLoading}
                    />
                    <SelectDropdown
                        name="type"
                        label="LMIA Type"
                        required={true}
                        placeholder="-- select lmia type --"
                        value={formData.type.value}
                        onChange={(option: ISelectDropdownOption) => onChangeSelect("type", option)}
                        errorMsg={formData.type.error}
                        options={!lmiaTypes?.length ? [] : lmiaTypes?.map((lmiaType: ILMIAType) => ({ value: lmiaType.id.toString(), label: lmiaType.type }))}
                        read_only={isLoading || isLMIATypesLoading || !lmiaTypes?.length}
                    />
                    <TextInput
                        name="lmia_number"
                        type="text"
                        max_length={10}
                        pattern_mask="doc_number"
                        required={true}
                        label="LMIA Number"
                        errorMsg={formData.lmia_number.error}
                        placeholder="LMIA Number"
                        onChange={(value: string) => onChangeText("lmia_number", value)}
                        value={formData.lmia_number.value}
                        read_only={isLoading}
                    />
                    <SelectDropdown
                        name="location"
                        label="Location"
                        required={true}
                        placeholder="-- select location --"
                        value={formData.location.value}
                        onChange={(option: ISelectDropdownOption) => onChangeSelect("location", option)}
                        errorMsg={formData.location.error}
                        options={!locations?.length ? [] : locations?.map((location: ILocation) => ({ value: location.id.toString(), label: location.identifier }))}
                        read_only={isLoading || isLocationsLoading}
                        addNewBtnData={{
                            name: "Location",
                            label: "Add Location",
                            action: () => navigate(`/employers/${employer_id}/locations/new`),
                        }}
                    />
                    <SelectDropdown
                        name="status"
                        label="Status"
                        required={true}
                        placeholder="-- select status --"
                        value={formData.status.value}
                        onChange={(option: ISelectDropdownOption) => onChangeSelect("status", option)}
                        errorMsg={formData.status.error}
                        options={OPLMIAStatuses}
                        read_only={isLoading}
                    />
                    <CalenderInput
                        name="submissionDate"
                        label="Submission Date"
                        errorMsg={formData.submissionDate.error}
                        placeholder="mm/dd/yyyy"
                        format="mm/dd/yyyy"
                        onChange={(value: string) => onChangeText("submissionDate", value)}
                        value={formData.submissionDate.value}
                        read_only={isLoading || formData.status.value.value === 'approved'}
                    />
                    <CalenderInput
                        name="issueDate"
                        label="Issue Date"
                        required={formData.status.value.value === 'approved'}
                        errorMsg={formData.issueDate.error}
                        placeholder="mm/dd/yyyy"
                        format="mm/dd/yyyy"
                        onChange={(value: string) => onChangeText("issueDate", value)}
                        value={formData.issueDate.value}
                        read_only={isLoading || formData.status.value.value !== 'approved'}
                    />
                    <CalenderInput
                        name="expiryDate"
                        label="Expiration Date"
                        required={formData.status.value.value === 'approved'}
                        errorMsg={formData.expiryDate.error}
                        placeholder="mm/dd/yyyy"
                        format="mm/dd/yyyy"
                        onChange={(value: string) => onChangeText("expiryDate", value)}
                        value={formData.expiryDate.value}
                        read_only={isLoading || formData.status.value.value !== 'approved'}
                    />
                    <SearchSelect
                        name="jobTitle"
                        label="Job Title"
                        required={true}
                        placeholder="Search job title"
                        value={formData.jobTitle.value}
                        onChange={(option: ISelectDropdownOption) => onChangeSelect("jobTitle", option)}
                        errorMsg={formData.jobTitle.error}
                        options={!jobTitles?.length ? [] : jobTitles.map(jobTitle => ({ value: jobTitle.job_title, label: `${jobTitle.noc_code.noc_code} - ${jobTitle.job_title}` }))}
                        read_only={isLoading || isJobTitlesLoading || !jobTitles?.length}
                        length_flex={2}
                    />
                    <TextInput
                        name="positions"
                        type="text"
                        pattern_mask="integer"
                        label="# of Positions"
                        required={true}
                        errorMsg={formData.positions.error}
                        placeholder="Positions"
                        onChange={(value: string) => onChangeText("positions", value)}
                        value={formData.positions.value}
                        read_only={isLoading}
                    />
                    <SelectDropdown
                        name="wageType"
                        label="Wage Type"
                        required={true}
                        placeholder="-- select wage type --"
                        value={formData.wageType.value}
                        onChange={(option: ISelectDropdownOption) => onChangeSelect("wageType", option)}
                        errorMsg={formData.wageType.error}
                        options={OPWageTypes}
                        read_only={isLoading}
                    />
                    <TextInput
                        name="wage"
                        type="text"
                        pattern_mask="currency_cad"
                        label="Wage"
                        required={true}
                        errorMsg={formData.wage.error}
                        placeholder="Wage"
                        onChange={(value: string) => onChangeText("wage", value)}
                        value={formData.wage.value}
                        read_only={isLoading}
                    />
                </div>
            </div>
            <div className="immployer__details_form_actions">
                <button
                    className={`immployer__btn immployer__btn__secondary ${isProcessing ? "immployer__btn__secondary__disabled" : ""}`}
                    onClick={() => isProcessing ? null : navigate(`/employers/${employer_id}/lmias`)}
                >
                    {
                        operation === 'create'
                            ? "Discard"
                            : "Go back"
                    }
                </button>
                <button
                    className={`immployer__btn immployer__btn__primary ${isDisabled() ? "immployer__btn__primary__disabled" : ""}`}
                    onClick={onSaveData}
                >
                    {
                        isProcessing
                            ? <i className='immployer__btn_loader' />
                            : 'Save'
                    }
                </button>
            </div>
        </div>
    );
};

export default LMIADetailsFormView;