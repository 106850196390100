import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import LMIAsListTable from '../../../sub_components/tables/LMIAsList';
// import { SearchInput } from '../../../reusables/Inputs';
import EmployerDetailsLayout from '../../../layouts/details/Employer';
import { SVGIcon } from '../../../wrappers/SVGIcon';

import useGetLMIAs from '../../../../hooks/lmias/useGetLMIAs';
import useGetEmployerDetails from '../../../../hooks/employers/useGetEmployerDetails';

import { Icons } from '../../../../data/Assets';
import { ISortQuery } from '../../../../interfaces/services';

const LMIAsPage = () => {
    const { employer_id } = useParams();
    const navigate = useNavigate();

    const [sortQuery, setSortQuery] = React.useState<ISortQuery>({ sort_key: 'lmia_number', sort_order: 'ASC' });

    const { data: employer } = useGetEmployerDetails(employer_id);
    const { data: lmias, isLoading } = useGetLMIAs(employer_id, sortQuery);

    return (
        <EmployerDetailsLayout
            operation={null}
            currentTab="LMIAs"
            title={employer?.legal_name ?? 'Employer Information'}
            error={false}
        >
            <div className='immployer__common_details__sub_info_view'>
                <div className="immployer__lists__page_view">
                    <br />
                    <div className="immployer__lists__top_section">
                        {/* <SearchInput /> */}
                        <button
                            onClick={() => navigate(`/employers/${employer_id}/lmias/new`)}
                            className="immployer__btn immployer__btn__primary"
                        >
                            <SVGIcon src={Icons.Plus} color='white' />
                            <span>Add&nbsp;LMIA</span>
                        </button>
                    </div>
                    <LMIAsListTable
                        lmias={lmias ?? []}
                        isLoading={isLoading}
                        sortQuery={sortQuery}
                        setSortQuery={setSortQuery}
                    />
                </div>
            </div>
        </EmployerDetailsLayout>
    );
};

export default LMIAsPage;