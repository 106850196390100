import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { closeModal, openModal } from "../../../../../store/slices/modal";

import { IVisaProps } from "../../../../../interfaces/foreign_worker";
import { IVisa } from "../../../../../interfaces/entities";

import VisaForm from "../../../../sub_components/immigration/VisaForm";
import { SVGIcon } from "../../../../wrappers/SVGIcon";

import { Icons } from "../../../../../data/Assets";

const VisasView = (props: IVisaProps) => {
    const dispatch = useDispatch();
    const { worker_id } = useParams();
    const navigate = useNavigate();

    const { linked, visasData, passportsData, isLoading, setCurrentTab } = props;

    const [visas, setVisas] = useState<(IVisa | null)[]>([]);
    const [isGlobalLoading, setIsGlobalLoading] = useState<{ [key: number]: boolean }>({});
    const [hasUnsavedData, setHasUnsavedData] = useState<{ [key: number]: boolean }>({});

    const isDisabled = () => {
        let isDisabled = false;
        Object.entries(isGlobalLoading).forEach(([key, value]) => {
            if (value) isDisabled = true;
        });

        return isDisabled;
    }

    const isSafeToNavigate = () => {
        let safeToNavigate = true;
        Object.entries(hasUnsavedData).forEach(([key, value]) => {
            if (value) safeToNavigate = false;
        });

        return safeToNavigate;
    }

    const onGoBack = () => {
        if (!isSafeToNavigate()) {
            dispatch(openModal({
                name: 'MESSAGE_MODAL',
                data: {
                    view: 'CONFIRMATION',
                    closeBtn: true,
                    title: "Unsaved changes",
                    content: "You have unsaved data. Are you sure you want to leave this page?",
                    actionTxt: "Confirm",
                    action: () => {
                        dispatch(closeModal('MESSAGE_MODAL'));
                        setCurrentTab('Permits');
                    }
                }
            }));
        } else {
            setCurrentTab('Permits');
        }
    };

    const onGoNext = () => {
        if (!isSafeToNavigate()) {
            dispatch(openModal({
                name: 'MESSAGE_MODAL',
                data: {
                    view: 'CONFIRMATION',
                    closeBtn: true,
                    title: "Unsaved changes",
                    content: "You have unsaved data. Are you sure you want to leave this page?",
                    actionTxt: "Confirm",
                    action: () => {
                        dispatch(closeModal('MESSAGE_MODAL'));
                        navigate(`/foreign-workers/${worker_id}/notifications`)
                    }
                }
            }));
        } else {
            navigate(`/foreign-workers/${worker_id}/notifications`)
        }
    };

    const onAddNewRecord = () => {
        if (!isSafeToNavigate() || !visas?.[visas.length - 1] || linked) return;

        setVisas([...visas, null]);
    }

    useEffect(() => {
        if (visasData?.length) {
            setVisas([...visasData]);
        } else {
            setVisas([null]);
        }
    }, [visasData]); // eslint-disable-line

    return (
        <div className='immployer__details_form_view'>
            <div className="immployer__details_form_inputs__container">
                {
                    visas.map((visa, index) => (
                        <VisaForm
                            key={index}
                            index={index}
                            visaData={visa}
                            passportsData={passportsData}
                            linked={linked}
                            isLoading={isLoading}
                            hasUnsavedData={hasUnsavedData}
                            setVisaDatas={setVisas}
                            setIsGlobalLoading={setIsGlobalLoading}
                            setHasUnsavedData={setHasUnsavedData}
                        />
                    ))
                }
                <button
                    className={`immployer__details_form__add_more_btn ${!isSafeToNavigate() || !visas?.[visas.length - 1] || linked ? "immployer__details_form__add_more_btn__disabled" : ""}`}
                    onClick={onAddNewRecord}
                >
                    <SVGIcon src={Icons.PlusCircleFill} />
                    <span>Add new Visa</span>
                </button>
            </div>
            <div className="immployer__details_form_actions">
                <button
                    className={`immployer__btn immployer__btn__secondary ${isDisabled() ? "immployer__btn__secondary__disabled" : ""}`}
                    onClick={onGoBack}
                >
                    Go back
                </button>

                <button
                    className={`immployer__btn immployer__btn__primary ${isDisabled() ? "immployer__btn__primary__disabled" : ""}`}
                    onClick={onGoNext}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default VisasView;