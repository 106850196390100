import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// import { SearchInput } from '../../reusables/Inputs';
import ForeignWorkersListTable from '../../sub_components/tables/ForeignWorkersList';
import MainLayout from '../../layouts/Main';
import { SVGIcon } from '../../wrappers/SVGIcon';

import useGetForeignWorkersDataList from '../../../hooks/foreign_workers/useGetForeignWorkersDataList';

import { Icons } from '../../../data/Assets';

import { ISortQuery } from '../../../interfaces/services';

const ForeignWorkersPage = () => {
    const navigate = useNavigate();

    const [sortQuery, setSortQuery] = useState<ISortQuery>({ sort_key: 'first_name', sort_order: 'ASC' });

    const { data: workers, isLoading } = useGetForeignWorkersDataList('organization', undefined, sortQuery);

    return (
        <MainLayout>
            <div className="immployer__lists__page_view">
                <h2 className="color_dark">Foreign Workers</h2>
                <br /><br />
                <div className="immployer__lists__top_section">
                    {/* <SearchInput /> */}
                    <button
                        onClick={() => navigate(`/foreign-workers/new`, { state: { goBackRoute: '/foreign-workers' } })}
                        className="immployer__btn immployer__btn__primary"
                    >
                        <SVGIcon src={Icons.Plus} color='white' />
                        <span>Add&nbsp;Worker</span>
                    </button>
                </div>
                <ForeignWorkersListTable
                    goBackRoute={'/foreign-workers'}
                    workers={workers ?? []}
                    isLoading={isLoading}
                    sortQuery={sortQuery}
                    setSortQuery={setSortQuery}
                />
            </div>
        </MainLayout>
    );
};

export default ForeignWorkersPage;