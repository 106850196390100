import React from "react";
import { Link } from "react-router-dom";

import { Icons, Logos } from "../../data/Assets";

import { SVGIcon } from "../wrappers/SVGIcon";
import { ImageAsset } from "../wrappers/ImageAsset";

// import { SearchInput } from "../reusables/Inputs";

const Header = () => {

    return (
        <div className="immployer__header">
            <div className="immployer__header__logo_sec">
                <Link to="/" className='immployer__header__logo'>
                    <ImageAsset src={Logos.Logo} alt="" className='fit_asset_max' />
                </Link>
            </div>
            <div className="immployer__header__search_sec">
                {/* <SearchInput /> */}
            </div>
            <div className="immployer__header__menu_sec">
                <button className="header_icon_btn">
                    <SVGIcon src={Icons.Bell2Fill} color='primary' />
                </button>
                <button className="header_icon_btn">
                    <SVGIcon src={Icons.Chat3Fill} color='primary' />
                </button>
            </div>
        </div>
    );
};

export default Header;