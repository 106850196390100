import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { IColumnProps } from "../../../interfaces/tables";
import { ISortQuery } from "../../../interfaces/services";
import { EmployerField } from "../../../interfaces/employer";

import { Icons } from "../../../data/Assets";

import { getElipsisString, getFormattedString } from "../../../helpers/utility";

import useGetEmployers from "../../../hooks/employers/useGetEmployers";

import { SVGIcon } from "../../wrappers/SVGIcon";

const EmployersListTable = () => {

    const [sortQuery, setSortQuery] = useState<ISortQuery>({ sort_key: 'cra_number', sort_order: 'ASC' });

    const { data: employers, isLoading } = useGetEmployers(sortQuery);

    const navigate = useNavigate();

    const columns: IColumnProps[] = [
        { name: "CRA Number", identifier: 'cra_number', sort: true, buttonClass: 'btn_block' },
        { name: "Legal Name", identifier: 'legal_name', sort: true, buttonClass: 'btn_block' },
        { name: "Operating Name", identifier: 'operating_name', sort: true, buttonClass: 'btn_block' },
        { name: "Type", identifier: 'type', sort: true, buttonClass: 'btn_block' },
        { name: "Industry Type", identifier: 'naics', sort: true, buttonClass: 'btn_block' },
        // { name: "Is Tech Company?", identifier: 'tech_company', sort: true, buttonClass: 'btn_block' }
    ];

    const handleSort = (sort_key: EmployerField) => {
        setSortQuery({
            sort_key,
            sort_order: sortQuery.sort_order === 'ASC' ? 'DESC' : 'ASC'
        });
    };

    return (
        <div className="immployer__list_table__container">
            <table className="immployer__list_table">
                <thead>
                    <tr>
                        {columns.map((column, index) =>
                            <th key={index}>
                                <div>
                                    <button onClick={() => column.sort ? handleSort(column.identifier as EmployerField ?? 'cra_number') : null} className={`${column.buttonClass ?? ''}`}>
                                        {column.name}
                                        {column?.sort && column.identifier === sortQuery.sort_key && <SVGIcon src={Icons.ArrowDownUp} color='primary' />}
                                    </button>
                                </div>
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {
                        isLoading
                            ? <tr>
                                <td colSpan={columns.length}>
                                    <div className="display_flex flex_dir_column display_flex_center">
                                        <span className="wt_600 pb_10">Loading</span>
                                        <i className='immployer__list_loader' />
                                    </div>
                                </td>
                            </tr>
                            : !employers?.length
                                ? <tr>
                                    <td colSpan={columns.length} className='text_center'>
                                        No records found.
                                    </td>
                                </tr>
                                : employers.map((employer, index) =>
                                    <tr
                                        key={index}
                                        onClick={() => navigate(`/employers/${employer.id}/details`)}
                                    >
                                        <td>{employer.cra_number}</td>
                                        <td>{employer.legal_name}</td>
                                        <td>{employer.operating_name}</td>
                                        <td>{getFormattedString(employer.type, "title_case")}</td>
                                        <td>{getElipsisString(employer.naics, 50)}</td>
                                        {/* <td>{employer.tech_company ? 'Yes' : 'No'}</td> */}
                                    </tr>
                                )
                    }
                </tbody>
            </table>
        </div>
    );
};

export default EmployersListTable;