import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import moment from "moment";

import { closeModal, openModal } from "../../../../store/slices/modal";

import { IWorkerEmploymentProps } from "../../../../interfaces/foreign_worker";
import { IWorkerJob } from "../../../../interfaces/entities";
import { ISortQuery } from "../../../../interfaces/services";

import useGetLocations from "../../../../hooks/locations/useGetLocations";
import useGetJobTitles from "../../../../hooks/jobs/useGetJobTitles";

import EmploymentForm from "../../../sub_components/employment/EmploymentForm";
import { BoxNote } from "../../../reusables/Cards";
import { SVGIcon } from "../../../wrappers/SVGIcon";

import { Icons } from "../../../../data/Assets";

const WorkerEmploymentDetailsFormView = (props: IWorkerEmploymentProps) => {
    const { worker_id } = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const { goBackRoute, isLoading, workerJobs } = props;

    const [workerJobDatas, setWorkerJobDatas] = useState<(IWorkerJob | null)[]>([]);
    const [hasUnsavedData, setHasUnsavedData] = useState<{ [key: number]: boolean }>({});
    const [isGlobalLoading, setIsGlobalLoading] = useState<{ [key: number]: boolean }>({});
    const [activeJobs, setActiveJobs] = useState<{ [key: number]: boolean }>({});
    const [areMultipleActiveJobs, setAreMultipleActiveJobs] = useState<boolean>(false);
    const [jobDurations, setJobDurations] = useState<{ [key: number]: [string, string] }>({});
    const [areJobsOverlapping, setAreJobsOverlapping] = useState<boolean>(false);

    const sortQuery: ISortQuery = { sort_key: 'identifier', sort_order: 'ASC' };

    const { data: locations, isLoading: isLocationsLoading } = useGetLocations('organization', undefined, sortQuery);
    const { data: jobTitles, isLoading: isJobTitlesLoading } = useGetJobTitles();

    const setOverlappingConditions = () => {
        const hasMultipleJobs = Object.entries(activeJobs).filter(([key, value]) => value);
        setAreMultipleActiveJobs(hasMultipleJobs.length > 1);

        if (Object.keys(jobDurations).length > 1) {
            const jobDurationsArr = Object.entries(jobDurations).map(([key, value]) => value);
            jobDurationsArr.sort((a, b) => moment.utc(a[0]).get('milliseconds') - moment.utc(b[0]).get('milliseconds'));

            let endDate = moment.utc(jobDurationsArr[0][1], "MM/DD/YYYY").toDate();

            for (let i = 1; i < jobDurationsArr.length; i++) {
                const [jobStart, jobEnd] = jobDurationsArr[i];
                let jobStartDate = moment.utc(jobStart, "MM/DD/YYYY").toDate();
                let jobEndDate = moment.utc(jobEnd, "MM/DD/YYYY").toDate();

                if (jobStartDate <= endDate) {
                    setAreJobsOverlapping(true);
                    return;
                }

                endDate = jobEndDate;
            }

            setAreJobsOverlapping(false);
        }
    };

    useEffect(() => {
        if (workerJobs?.length && jobTitles?.length && locations?.length) {
            setWorkerJobDatas(([...workerJobs]));
        } else {
            setWorkerJobDatas([null]);
        }

        // eslint-disable-next-line
    }, [workerJobs, jobTitles, locations])

    useEffect(() => {
        setOverlappingConditions();
        // eslint-disable-next-line
    }, [activeJobs, jobDurations]);

    const isDisabled = () => {
        let isDisabled = false;
        Object.entries(isGlobalLoading).forEach(([key, value]) => {
            if (value) isDisabled = true;
        });

        return isDisabled;
    }

    const isSafeToNavigate = () => {
        let safeToNavigate = true;
        Object.entries(hasUnsavedData).forEach(([key, value]) => {
            if (value) safeToNavigate = false;
        });

        return safeToNavigate;
    }

    const onAddRecord = () => {
        if (!isSafeToNavigate() || !workerJobDatas?.[workerJobDatas.length - 1]) return;

        setWorkerJobDatas(prevData => [...prevData, null]);
    };


    const onGoBack = () => {
        if (!isSafeToNavigate()) {
            dispatch(openModal({
                name: 'MESSAGE_MODAL',
                data: {
                    view: 'CONFIRMATION',
                    closeBtn: true,
                    title: "Unsaved changes",
                    content: "You have unsaved data. Are you sure you want to leave this page?",
                    actionTxt: "Confirm",
                    action: () => {
                        dispatch(closeModal('MESSAGE_MODAL'));
                        if (goBackRoute) {
                            navigate(goBackRoute);
                        } else {
                            navigate(`/foreign-workers`);
                        };
                    }
                }
            }));
        } else {
            if (goBackRoute) {
                navigate(goBackRoute);
            } else {
                navigate(`/foreign-workers`);
            }
        }
    };

    const onGoNext = () => {
        if (!isSafeToNavigate()) {
            dispatch(openModal({
                name: 'MESSAGE_MODAL',
                data: {
                    view: 'CONFIRMATION',
                    closeBtn: true,
                    title: "Unsaved changes",
                    content: "You have unsaved data. Are you sure you want to leave this page?",
                    actionTxt: "Confirm",
                    action: () => {
                        dispatch(closeModal('MESSAGE_MODAL'));
                        navigate(`/foreign-workers/${worker_id}/immigration`);
                    }
                }
            }));
        } else {
            navigate(`/foreign-workers/${worker_id}/immigration`);
        }
    };

    return (
        <div className='immployer__details_form_view'>
            <div className="immployer__details_form_inputs__container">
                {workerJobDatas.map((workerJobData, index) => (
                    <EmploymentForm
                        key={index}
                        index={index}
                        length={workerJobs.length}
                        workerJob={workerJobData}
                        jobTitles={jobTitles ?? []}
                        locations={locations ?? []}
                        isLoading={isJobTitlesLoading || isLocationsLoading || isLoading}
                        hasUnsavedData={hasUnsavedData}
                        setWorkerJobDatas={setWorkerJobDatas}
                        setActiveJobs={setActiveJobs}
                        setJobDurations={setJobDurations}
                        setIsGlobalLoading={setIsGlobalLoading}
                        setHasUnsavedData={setHasUnsavedData}
                    />
                ))
                }
                <button
                    className={`immployer__details_form__add_more_btn ${!isSafeToNavigate() || !workerJobDatas?.[workerJobDatas.length - 1] ? "immployer__details_form__add_more_btn__disabled" : ""}`}
                    onClick={onAddRecord}
                >
                    <SVGIcon src={Icons.PlusCircleFill} />
                    <span>Add new employment record</span>
                </button>
                {(areMultipleActiveJobs || areJobsOverlapping) &&
                    <>
                        <br />
                        <BoxNote
                            code="error"
                            title="Overlapping Jobs"
                            content={(
                                <p className="text_italic mice">
                                    {areMultipleActiveJobs && "You have multiple active jobs. Please ensure that you have only one active job at a time."}
                                    {areMultipleActiveJobs && areJobsOverlapping && <br />}
                                    {areJobsOverlapping && "You have overlapping jobs. Please ensure that the start and end dates of your jobs do not overlap."}
                                </p>
                            )}
                        />
                        <br />
                    </>
                }

                <div className="immployer__details_form_actions">
                    <button
                        className={`immployer__btn immployer__btn__secondary ${isDisabled() ? "immployer__btn__secondary__disabled" : ""}`}
                        onClick={onGoBack}
                    >
                        Go back
                    </button>
                    <button
                        className={`immployer__btn immployer__btn__primary ${isDisabled() ? "immployer__btn__primary__disabled" : ""}`}
                        onClick={onGoNext}
                    >
                        Next
                    </button>
                </div>
            </div>
        </div >
    );
};

export default WorkerEmploymentDetailsFormView;