import React from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from 'react-redux';

import { getModalData, isModalActive, closeModal } from "../../store/slices/modal";

import { ConfirmationView, InvitationView, NotificationView, UnlinkForeignWorkerView, WarningView } from "../views/modals/Message";
import { SVGIcon } from "../wrappers/SVGIcon";

import { IMessageModalData } from "../../interfaces/modals";

import { Icons } from "../../data/Assets";

const modalName = "MESSAGE_MODAL";
const modalTitle = "Message";
const modalClass = "message";

ReactModal.setAppElement('#root');
const MessageModal = () => {
    const dispatch = useDispatch();

    const isModalVisible: boolean = useSelector(isModalActive(modalName));
    const modalData: IMessageModalData | null = useSelector(getModalData(modalName));
    const { closeOnEsc = true, closeOnOutsideClick = true, closeBtn = true, view } = modalData || {};

    const afterOpenModal = () => { };

    const onCloseModal = () => {
        dispatch(closeModal(modalName));
    };

    return (
        <ReactModal
            id={modalName}
            isOpen={isModalVisible && !!modalData}
            onAfterOpen={afterOpenModal}
            onRequestClose={onCloseModal}
            contentLabel={modalTitle}
            overlayClassName={`immployer__modal_overlay immployer__modal_overlay__${modalClass}`}
            className={`immployer__modal_dialog immployer__modal_dialog__${modalClass}`}
            shouldCloseOnEsc={closeOnEsc}
            shouldCloseOnOverlayClick={closeOnOutsideClick}
        >
            <div className={`immployer__modal_content immployer__modal_content__${modalClass}`}>
                {closeBtn &&
                    <button className="immployer__modal_close_btn" onClick={onCloseModal}>
                        <SVGIcon src={Icons.X} />
                    </button>
                }
                {view === "INVITATION" &&
                    <InvitationView
                        foreignWorkerId={modalData?.foreignWorkerId ?? null}
                        onClose={onCloseModal}
                    />
                }
                {view === "UNLINK_FOREIGN_WORKER" &&
                    <UnlinkForeignWorkerView
                        foreignWorkerId={modalData?.foreignWorkerId ?? null}
                        onClose={onCloseModal}
                    />
                }
                {view === "CONFIRMATION" &&
                    <ConfirmationView
                        title={modalData?.title ?? ""}
                        content={modalData?.content as string ?? ""}
                        action={() => modalData?.action ? modalData?.action() : null}
                        actionTxt={modalData?.actionTxt ?? ""}
                        onClose={onCloseModal}
                        cancelFn={() => modalData?.cancelFn ? modalData?.cancelFn() : null}
                        cancelTxt={modalData?.cancelTxt ?? "Cancel"}
                    />
                }
                {view === 'NOTIFICATION' &&
                    <NotificationView
                        onClose={onCloseModal}
                        notifications={modalData?.notifications ?? []}
                        documentType={modalData?.documentType ?? ''}
                        operation={modalData?.operation ?? ''}
                        documentPayload={modalData?.documentPayload}
                        isProcessing={modalData?.isProcessing ?? false}
                        setIsProcessing={modalData?.setIsProcessing ?? (() => { })}
                    />
                }
                {view === 'WARNING' &&
                    <WarningView
                        onClose={onCloseModal}
                        title={modalData?.title ?? ""}
                        subtitle={modalData?.subtitle ?? ""}
                        content={modalData?.content as string[] ?? []}
                        cancelFn={() => modalData?.cancelFn ? modalData?.cancelFn() : null}
                        cancelTxt={modalData?.cancelTxt ?? "Cancel"}
                    />
                }
            </div>
        </ReactModal>
    );
};

export default MessageModal;