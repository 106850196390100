import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import moment from "moment";

import { CalenderInput, TextInput } from "../../../reusables/FormElements";
import ToolTip from "../../../reusables/ToolTip";

import { Icons } from "../../../../data/Assets";

import { IWorkerPersonalProps, WorkerPersonalField } from "../../../../interfaces/foreign_worker";

import validationHelper from "../../../../helpers/validators";

import useGetSinExpiryNotification from "../../../../hooks/expiry_schedules/useGetSinExpiryNotification";

import { getDateString, getPhoneNumberString } from "../../../../helpers/utility";
import { ISaveForeignWorker, ISinExpiryNotificationPayload } from "../../../../interfaces/services";

const WorkerPersonalDetailsFormView = (props: IWorkerPersonalProps) => {
    const navigate = useNavigate();

    const { goBackRoute, operation, linked, workerData, isLoading, formData, setFormData } = props;

    const requiredFields: WorkerPersonalField[] = ["firstName", "lastName", "personalEmail"];

    const [isProcessing, setIsProcessing] = useState(false);

    const { mutate: getSinExpiryNotification } = useGetSinExpiryNotification();

    useEffect(() => {
        if (workerData?.worker_details?.length) {
            const workerDetails = workerData.worker_details[0];
            const birthDate = workerDetails.birth_date ? getDateString(workerDetails.birth_date) : null;
            const sinExpiryDate = workerDetails.sin_expiry ? getDateString(workerDetails.sin_expiry) : null;
            const phone = workerDetails.phone_number ? getPhoneNumberString(workerDetails.phone_number) : null;

            setFormData({
                id: workerData.id,
                firstName: { value: workerDetails.first_name ?? '', error: "" },
                middleName: { value: workerDetails.middle_name ?? '', error: "" },
                lastName: { value: workerDetails.last_name ?? '', error: "" },
                birthDate: { value: birthDate ?? '', error: "" },
                phone: { value: phone ?? '', error: "" },
                personalEmail: { value: workerDetails.email ?? '', error: "" },
                sinExpiryDate: { value: sinExpiryDate ?? '', error: "" }
            });
        };

        // eslint-disable-next-line
    }, [workerData])

    const onChangeText = (name: WorkerPersonalField, value: string) => {
        setFormData({
            ...formData,
            [name]: { value, error: "" }
        });
    };

    const isDisabled = () => {
        const emptyFields = requiredFields.some(field => !formData[field].value?.trim()?.length);

        const workerDetails = workerData?.worker_details[0] ?? null;

        const birthDate = workerDetails?.birth_date ? getDateString(workerDetails.birth_date) : null;
        const sinExpiryDate = workerDetails?.sin_expiry ? getDateString(workerDetails.sin_expiry) : null;
        const phone = workerDetails?.phone_number ? getPhoneNumberString(workerDetails.phone_number) : null;

        const isDataSame =
            formData.firstName.value === (workerDetails?.first_name ?? '') &&
            formData.middleName.value === (workerDetails?.middle_name ?? '') &&
            formData.lastName.value === (workerDetails?.last_name ?? '') &&
            formData.personalEmail.value === (workerDetails?.email ?? '') &&
            formData.phone.value === (phone ?? '') &&
            formData.birthDate.value === (birthDate ?? '') &&
            formData.sinExpiryDate.value === (sinExpiryDate ?? '');

        return emptyFields || isDataSame || linked || isProcessing || isLoading;
    };

    const onSaveData = () => {
        if (isDisabled()) return;

        // clear all errors
        setFormData(prevData => {
            const newData = { ...prevData };
            Object.keys(newData).forEach(key => {
                if (key === "id") return;
                newData[key as WorkerPersonalField].error = "";
            });
            return newData;
        });

        const emailValError = validationHelper.validateInput("email", formData.personalEmail.value);
        if (emailValError) {
            setFormData({
                ...formData,
                personalEmail: { ...formData.personalEmail, error: emailValError }
            });
            return;
        };

        setIsProcessing(true);

        const foreignWorkerPayload: ISaveForeignWorker = {
            id: formData.id ?? null,
            first_name: formData.firstName.value,
            middle_name: formData.middleName.value?.trim()?.length ? formData.middleName.value : null,
            last_name: formData.lastName.value,
            email: formData.personalEmail.value,
            phone_number: formData.phone.value?.trim()?.length ? formData.phone.value.replace(/\D/g, '') : null,
            birth_date: formData.birthDate.value?.trim()?.length ? moment.utc(formData.birthDate.value).toDate().toISOString() : null,
            sin_expiry: formData.sinExpiryDate.value?.trim()?.length ? moment.utc(formData.sinExpiryDate.value).toDate().toISOString() : null
        }

        const sinExpiryPayload: ISinExpiryNotificationPayload = {
            foreign_worker_id: formData.id ?? 0,
            expiry_date: formData.sinExpiryDate.value?.trim()?.length ? moment.utc(formData.sinExpiryDate.value).toDate() : null
        }

        getSinExpiryNotification({
            payload: sinExpiryPayload,
            documentType: 'sin',
            documentPayload: foreignWorkerPayload,
            operation,
            isProcessing,
            setIsProcessing,
        });
    };

    const onGoBack = () => {
        if (isProcessing) return;

        if (goBackRoute) {
            navigate(goBackRoute);
        } else {
            navigate(`/foreign-workers`);
        };
    };

    return (
        <div className='immployer__details_form_view'>
            <div className="immployer__details_form_inputs__container">
                <div className="immployer__details_form_inputs">
                    <TextInput
                        name="firstName"
                        type="text"
                        max_length={50}
                        label="First Name"
                        required={true}
                        errorMsg={formData.firstName.error}
                        placeholder="First Name"
                        onChange={(value: string) => onChangeText("firstName", value)}
                        value={formData.firstName.value}
                        read_only={isLoading || linked}
                    />
                    <TextInput
                        name="middleName"
                        type="text"
                        max_length={50}
                        label="Middle Name"
                        errorMsg={formData.middleName.error}
                        placeholder="Middle Name"
                        onChange={(value: string) => onChangeText("middleName", value)}
                        value={formData.middleName.value}
                        read_only={isLoading || linked}
                    />
                    <TextInput
                        name="lastName"
                        type="text"
                        max_length={50}
                        label="Last Name"
                        required={true}
                        errorMsg={formData.lastName.error}
                        placeholder="Last Name"
                        onChange={(value: string) => onChangeText("lastName", value)}
                        value={formData.lastName.value}
                        read_only={isLoading || linked}
                    />
                    <TextInput
                        name="personalEmail"
                        type="text"
                        max_length={100}
                        label="Personal Email"
                        tooltip={<ToolTip icon={Icons.InfoCircle} message="Enter your personal email address where the invitation will be sent." />}
                        required={true}
                        errorMsg={formData.personalEmail.error}
                        placeholder="Email address"
                        onChange={(value: string) => onChangeText("personalEmail", value)}
                        value={formData.personalEmail.value}
                        read_only={isLoading || linked}
                    />
                    <CalenderInput
                        name="birthDate"
                        label="Date of Birth"
                        errorMsg={formData.birthDate.error}
                        placeholder="mm/dd/yyyy"
                        format="mm/dd/yyyy"
                        onChange={(value: string) => onChangeText("birthDate", value)}
                        value={formData.birthDate.value}
                        read_only={isLoading || linked}
                    />
                    <TextInput
                        name="phone"
                        type="text"
                        label="Phone"
                        errorMsg={formData.phone.error}
                        pattern_mask="phone_number"
                        max_length={12}
                        placeholder="650-000-0000"
                        onChange={(value: string) => onChangeText("phone", value)}
                        value={formData.phone.value}
                        read_only={isLoading || linked}
                    />
                    <CalenderInput
                        name="sinExpiryDate"
                        label="SIN Expiry"
                        errorMsg={formData.sinExpiryDate.error}
                        placeholder="mm/dd/yyyy"
                        format="mm/dd/yyyy"
                        onChange={(value: string) => onChangeText("sinExpiryDate", value)}
                        value={formData.sinExpiryDate.value}
                        read_only={isLoading || linked}
                    />
                </div>
            </div>
            <div className="immployer__details_form_actions">
                <button
                    className={`immployer__btn immployer__btn__secondary ${isProcessing ? "immployer__btn__secondary__disabled" : ""}`}
                    onClick={onGoBack}
                >
                    {
                        operation === 'create'
                            ? "Discard"
                            : "Go back"
                    }
                </button>
                <button
                    className={`immployer__btn immployer__btn__primary ${isDisabled() ? "immployer__btn__primary__disabled" : ""}`}
                    onClick={onSaveData}
                >
                    {
                        isProcessing
                            ? <i className='immployer__btn_loader' />
                            : 'Save'
                    }
                </button>
            </div>
        </div>
    );
};

export default WorkerPersonalDetailsFormView;