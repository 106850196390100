import { useQuery } from "@tanstack/react-query";

import api from "./apiClient";

export const useGetSignedUrl = (filePath: string | null) => {
    const queryFn = async (): Promise<string | null> => {
        if (!filePath) return null;

        const res = await api.get(`/misc/signed-url/asset/${filePath.replaceAll("/", ".slash.")}`);
        return res.data;
    };

    return useQuery({
        queryFn,
        queryKey: ['signed-url', filePath],
        enabled: !!filePath,
        staleTime: 24 * 60 * 60 * 1000, // Cache for 24 hours
        gcTime: 30 * 24 * 60 * 60 * 1000, // Keep in cache for 30 days
    });
};

export const useGetSVGContent = (filePath: string | null) => {
    const queryFn = async (): Promise<string | null> => {
        if (!filePath) return null;

        const res = await api.get(`/misc/svg-content/${filePath.replaceAll("/", ".slash.")}`);
        return res.data;
    };

    return useQuery({
        queryKey: ['svg-content', filePath],
        queryFn,
        enabled: !!filePath,
        staleTime: 24 * 60 * 60 * 1000, // Cache for 24 hours
        gcTime: 30 * 24 * 60 * 60 * 1000, // Keep in cache for 30 days
    });
};

