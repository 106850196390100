import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { SelectDropdown, TextInput } from "../../reusables/FormElements";

import { ILocationDetailsProps, LocationField } from "../../../interfaces/location";
import { ISelectDropdownOption } from "../../../interfaces/formElements";
import { TLocationType } from "../../../interfaces/entities.types";

import useSaveLocation from "../../../hooks/locations/useSaveLocation";

import { OPLocationTypes, OPProvinces } from "../../../data/options";

import { getPhoneNumberString } from "../../../helpers/utility";

const LocationDetailsFormView = (props: ILocationDetailsProps) => {
    const { employer_id } = useParams();
    const navigate = useNavigate();

    const { operation, isLoading, locationData, formData, setFormData } = props;

    const requiredFields: LocationField[] = ["identifier", "street", "city", "province", "postal", "phone", "type", "full_time_emp", "part_time_emp"];
    const selectFields: LocationField[] = ["province", "type"];

    const [isProcessing, setIsProcessing] = useState(false);

    const { mutate: saveLocation } = useSaveLocation();

    useEffect(() => {
        if (locationData) {
            const type = OPLocationTypes.find(type => type.value === locationData.type);
            const province = OPProvinces.find(province => province.value === locationData.province);
            const phone = locationData?.phone ? getPhoneNumberString(locationData.phone) : null;

            setFormData({
                id: locationData.id,
                identifier: { value: locationData.identifier ?? '', error: '' },
                street: { value: locationData.street ?? '', error: '' },
                city: { value: locationData.city ?? '', error: '' },
                province: { value: { value: province?.value ?? '', label: province?.label ?? '' }, error: '' },
                postal: { value: locationData.postal ?? '', error: '' },
                phone: { value: phone ?? '', error: '' },
                manager: { value: locationData.manager ?? '', error: '' },
                type: { value: { value: type?.value ?? '', label: type?.label ?? '' }, error: '' },
                full_time_emp: { value: locationData.full_time_emp?.toString() ?? '', error: '' },
                part_time_emp: { value: locationData.part_time_emp?.toString() ?? '', error: '' },
            });
        };

        // eslint-disable-next-line
    }, [locationData])

    const onChangeText = (name: LocationField, value: string) => {
        setFormData({
            ...formData,
            [name]: { value, error: "" }
        });
    };

    const onChangeSelect = (name: LocationField, option: ISelectDropdownOption) => {
        setFormData(prevData => {
            return {
                ...prevData,
                [name]: { value: option, error: "" }
            };
        });
    };

    const isDisabled = () => {
        const emptyFields = requiredFields.some(field => {
            let value = "";
            if (selectFields.includes(field)) {
                const dataValue = formData[field].value as ISelectDropdownOption;
                value = dataValue.value;
            } else {
                value = formData[field].value as string;
            };
            return !value?.trim()?.length;
        });

        const phone = locationData?.phone ? getPhoneNumberString(locationData.phone) : null;

        const isDataSame =
            formData.identifier.value === (locationData?.identifier ?? '') &&
            formData.street.value === (locationData?.street ?? '') &&
            formData.city.value === (locationData?.city ?? '') &&
            formData.province.value.value === (locationData?.province ?? '') &&
            formData.postal.value === (locationData?.postal ?? '') &&
            formData.phone.value === (phone ?? '') &&
            formData.manager.value === (locationData?.manager ?? '') &&
            formData.type.value.value === (locationData?.type ?? '') &&
            formData.full_time_emp.value === (locationData?.full_time_emp?.toString() ?? '') &&
            formData.part_time_emp.value === (locationData?.part_time_emp?.toString() ?? '');

        return emptyFields || isDataSame || isProcessing || isLoading;
    };

    const onSaveData = () => {
        if (isDisabled() || !employer_id) return;

        // clear all errors
        setFormData(prevData => {
            const newData = { ...prevData };
            Object.keys(newData).forEach(key => {
                if (key === "id") return;
                newData[key as LocationField].error = "";
            });
            return newData;
        });

        setIsProcessing(true);

        saveLocation({
            payload: {
                id: formData.id,
                employer_id: Number(employer_id),
                identifier: formData.identifier.value,
                street: formData.street.value,
                city: formData.city.value,
                province: formData.province.value.value,
                postal: formData.postal.value,
                phone: formData.phone.value.replace(/\D/g, ''),
                manager: formData.manager.value,
                type: formData.type.value.value as TLocationType,
                full_time_emp: Number(formData.full_time_emp.value),
                part_time_emp: Number(formData.part_time_emp.value),
            },
            operation,
            setIsProcessing,
        });
    };

    return (
        <div className='immployer__details_form_view'>
            <div className="immployer__details_form_inputs__container">
                <div className="immployer__details_form_inputs">
                    <TextInput
                        name="identifier"
                        type="text"
                        max_length={100}
                        label="Location Name"
                        required={true}
                        errorMsg={formData.identifier.error}
                        placeholder="Location Name"
                        onChange={(value: string) => onChangeText("identifier", value)}
                        value={formData.identifier.value}
                        read_only={isLoading}
                    />
                    <TextInput
                        name="manager"
                        type="text"
                        max_length={100}
                        label="Location Manager"
                        errorMsg={formData.manager.error}
                        placeholder="Manager name"
                        onChange={(value: string) => onChangeText("manager", value)}
                        value={formData.manager.value}
                        read_only={isLoading}
                    />
                    <TextInput
                        name="full_time_emp"
                        type="text"
                        pattern_mask="integer"
                        label="Full Time Employees"
                        required={true}
                        errorMsg={formData.full_time_emp.error}
                        placeholder="Full Time Employees"
                        onChange={(value: string) => onChangeText("full_time_emp", value)}
                        value={formData.full_time_emp.value}
                        read_only={isLoading}
                    />
                    <TextInput
                        name="part_time_emp"
                        type="text"
                        pattern_mask="integer"
                        label="Part Time Employees"
                        required={true}
                        errorMsg={formData.part_time_emp.error}
                        placeholder="Part Time Employees"
                        onChange={(value: string) => onChangeText("part_time_emp", value)}
                        value={formData.part_time_emp.value}
                        read_only={isLoading}
                    />
                    <SelectDropdown
                        name="type"
                        label="Location Type"
                        required={true}
                        placeholder="-- select location type --"
                        value={formData.type.value}
                        onChange={(option: ISelectDropdownOption) => onChangeSelect("type", option)}
                        errorMsg={formData.type.error}
                        options={OPLocationTypes}
                        read_only={isLoading}
                    />
                    <TextInput
                        name="street"
                        type="text"
                        max_length={100}
                        label="Street name and #"
                        required={true}
                        errorMsg={formData.street.error}
                        placeholder="Street name and #"
                        onChange={(value: string) => onChangeText("street", value)}
                        value={formData.street.value}
                        read_only={isLoading}
                    />
                    <TextInput
                        name="city"
                        type="text"
                        max_length={50}
                        label="City"
                        required={true}
                        errorMsg={formData.city.error}
                        placeholder="City"
                        onChange={(value: string) => onChangeText("city", value)}
                        value={formData.city.value}
                        read_only={isLoading}
                    />
                    <SelectDropdown
                        name="province"
                        label="Province"
                        required={true}
                        placeholder="-- select province --"
                        value={formData.province.value}
                        onChange={(option: ISelectDropdownOption) => onChangeSelect("province", option)}
                        errorMsg={formData.province.error}
                        options={OPProvinces}
                        read_only={isLoading}
                    />
                    <TextInput
                        name="postal"
                        type="text"
                        max_length={7}
                        label="Postal"
                        required={true}
                        errorMsg={formData.postal.error}
                        placeholder="Postal"
                        onChange={(value: string) => onChangeText("postal", value)}
                        value={formData.postal.value}
                        read_only={isLoading}
                    />
                    <TextInput
                        name="phone"
                        type="text"
                        pattern_mask="phone_number"
                        max_length={12}
                        label="Phone"
                        required={true}
                        errorMsg={formData.phone.error}
                        placeholder="650-000-0000"
                        onChange={(value: string) => onChangeText("phone", value)}
                        value={formData.phone.value}
                        read_only={isLoading}
                    />
                </div>
            </div>
            <div className="immployer__details_form_actions">
                <button
                    className={`immployer__btn immployer__btn__secondary ${isProcessing ? "immployer__btn__secondary__disabled" : ""}`}
                    onClick={() => isProcessing ? null : navigate(`/employers/${employer_id}/locations`)}
                >
                    {
                        operation === 'create'
                            ? "Discard"
                            : "Go back"
                    }
                </button>
                <button
                    className={`immployer__btn immployer__btn__primary ${isDisabled() ? "immployer__btn__primary__disabled" : ""}`}
                    onClick={onSaveData}
                >
                    {
                        isProcessing
                            ? <i className='immployer__btn_loader' />
                            : 'Save'
                    }
                </button>
            </div>
        </div>
    );
};

export default LocationDetailsFormView;