import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

import useGetPermitTypes from "../../../../../hooks/permit_types/useGetPermitTypes";
import useGetLMIAsForForeignWorker from "../../../../../hooks/lmias/useGetLMIAsForForeignWorker";

import { IPermit } from "../../../../../interfaces/entities";
import { IPermitProps } from "../../../../../interfaces/foreign_worker";

import { closeModal, openModal } from "../../../../../store/slices/modal";

import PermitForm from "../../../../sub_components/immigration/PermitForm";
import { SVGIcon } from "../../../../wrappers/SVGIcon";

import { Icons } from "../../../../../data/Assets";

const PermitsView = (props: IPermitProps) => {
    const { worker_id } = useParams();
    const dispatch = useDispatch();

    const { linked, permitsData, passportsData, isLoading, setCurrentTab } = props;

    const [permits, setPermits] = useState<(IPermit | null)[]>([]);
    const [isGlobalLoading, setIsGlobalLoading] = useState<{ [key: number]: boolean }>({});
    const [hasUnsavedData, setHasUnsavedData] = useState<{ [key: number]: boolean }>({});

    const { data: lmiasRes } = useGetLMIAsForForeignWorker(worker_id);
    const { data: permitTypes, isLoading: isPermitTypesLoading } = useGetPermitTypes();

    useEffect(() => {
        if (permitsData?.length) {
            setPermits([...permitsData]);
        } else {
            setPermits([null]);
        }
    }, [permitsData]); // eslint-disable-line

    const isDisabled = () => {
        let isDisabled = false;
        Object.entries(isGlobalLoading).forEach(([key, value]) => {
            if (value) isDisabled = true;
        });

        return isDisabled;
    }

    const isSafeToNavigate = () => {
        let safeToNavigate = true;
        Object.entries(hasUnsavedData).forEach(([key, value]) => {
            if (value) safeToNavigate = false;
        });

        return safeToNavigate;
    }

    const onGoBack = () => {
        if (!isSafeToNavigate()) {
            dispatch(openModal({
                name: 'MESSAGE_MODAL',
                data: {
                    view: 'CONFIRMATION',
                    closeBtn: true,
                    title: "Unsaved changes",
                    content: "You have unsaved data. Are you sure you want to leave this page?",
                    actionTxt: "Confirm",
                    action: () => {
                        dispatch(closeModal('MESSAGE_MODAL'));
                        setCurrentTab('Passports');
                    }
                }
            }));
        } else {
            setCurrentTab('Passports');
        }
    };

    const onGoNext = () => {
        if (!isSafeToNavigate()) {
            dispatch(openModal({
                name: 'MESSAGE_MODAL',
                data: {
                    view: 'CONFIRMATION',
                    closeBtn: true,
                    title: "Unsaved changes",
                    content: "You have unsaved data. Are you sure you want to leave this page?",
                    actionTxt: "Confirm",
                    action: () => {
                        dispatch(closeModal('MESSAGE_MODAL'));
                        setCurrentTab('Visas');
                    }
                }
            }));
        } else {
            setCurrentTab('Visas');
        }
    };

    const onAddNewRecord = () => {
        if (!isSafeToNavigate() || !permits?.[permits.length - 1] || linked) return;

        setPermits([...permits, null]);
    }

    return (
        <div className={`immployer__details_form_view`}>
            <div className="immployer__details_form_inputs__container">
                {
                    permits.map((permit, index) => (
                        <PermitForm
                            key={index}
                            index={index}
                            permitData={permit}
                            passportsData={passportsData}
                            lmiasRes={lmiasRes}
                            permitTypes={permitTypes}
                            linked={linked}
                            isLoading={isLoading}
                            isPermitTypesLoading={isPermitTypesLoading}
                            hasUnsavedData={hasUnsavedData}
                            setPermitDatas={setPermits}
                            setIsGlobalLoading={setIsGlobalLoading}
                            setHasUnsavedData={setHasUnsavedData}
                        />
                    ))
                }
                <button
                    className={`immployer__details_form__add_more_btn ${!isSafeToNavigate() || !permits?.[permits.length - 1] || linked ? "immployer__details_form__add_more_btn__disabled" : ""}`}
                    onClick={onAddNewRecord}
                >
                    <SVGIcon src={Icons.PlusCircleFill} />
                    <span>Add new Permit</span>
                </button>
            </div>
            <div className="immployer__details_form_actions">
                <button
                    className={`immployer__btn immployer__btn__secondary ${isDisabled() ? "immployer__btn__secondary__disabled" : ""}`}
                    onClick={onGoBack}
                >
                    Go back
                </button>

                <button
                    className={`immployer__btn immployer__btn__primary ${isDisabled() ? "immployer__btn__primary__disabled" : ""}`}
                    onClick={onGoNext}
                >
                    Next
                </button>
            </div>
        </div>
    );
};

export default PermitsView;