import { CancelTokenSource } from 'axios';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import moment from 'moment';

import useParseDocument from '../../../hooks/documents/useParseDocument';
import useGetPreSignedUrl from '../../../hooks/documents/useGetPreSignedUrl';
import useDeleteDocument from '../../../hooks/documents/useDeleteDocument';
import useGetDocumentExpiryNotification from '../../../hooks/expiry_schedules/useGetDocumentExpiryNotification';
import useDeletePermit from '../../../hooks/permits/useDeletePermit';

import { ILMIA, IPassport, IPermit, IPermitType } from "../../../interfaces/entities";
import { IPermitData, PermitField } from '../../../interfaces/foreign_worker';
import { ISelectDropdownOption } from '../../../interfaces/formElements';

import { OPDocumentStatuses } from '../../../data/options';

import { IGenericDocument } from '../../../interfaces/services';
import { TDocStatus } from '../../../interfaces/entities.types';

import { closeModal, openModal } from '../../../store/slices/modal';

import UploadFileBtn from './UploadFileBtn';
import PreviewPanel from './PreviewPanel';
import ImmigrationFormAction from './ImmigrationFormAction';
import { CalenderInput, SelectDropdown, TextInput } from '../../reusables/FormElements';

import { getDateString } from '../../../helpers/utility';

interface IPermitFormProps {
    index: number;
    permitData: IPermit | null;
    passportsData: IPassport[];
    lmiasRes: ILMIA[] | undefined;
    permitTypes: IPermitType[] | undefined;
    linked: boolean;
    isLoading: boolean;
    isPermitTypesLoading: boolean;
    hasUnsavedData: { [key: number]: boolean; };
    setPermitDatas: Dispatch<SetStateAction<(IPermit | null)[]>>;
    setIsGlobalLoading: Dispatch<SetStateAction<{ [key: number]: boolean }>>;
    setHasUnsavedData: Dispatch<SetStateAction<{ [key: number]: boolean }>>;
}

const PermitForm = (props: IPermitFormProps) => {
    const { worker_id } = useParams();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { permitData, passportsData, lmiasRes, permitTypes, isPermitTypesLoading, hasUnsavedData, setPermitDatas, linked, isLoading, index, setIsGlobalLoading, setHasUnsavedData } = props;

    const requiredFields: PermitField[] = ["passport", "permitNumber", "permitType", "caseType", "status"];
    const selectFields: PermitField[] = ["passport", "permitType", "lmia", "status"];

    const initialFormData: IPermitData = {
        id: null,
        document_id: { value: 0, error: '' },
        passport: { value: { value: '', label: '' }, error: '' },
        permitNumber: { value: '', error: '' },
        permitType: { value: { value: '', label: '' }, error: '' },
        lmia: { value: { value: '', label: '' }, error: '' },
        caseType: { value: '', error: '' },
        company: { value: '', error: '' },
        submissionDate: { value: '', error: '' },
        issueDate: { value: '', error: '' },
        expiryDate: { value: '', error: '' },
        refusedDate: { value: '', error: '' },
        status: { value: { value: '', label: '' }, error: '' },
        document: null,
    }

    const [formData, setFormData] = useState<IPermitData>(initialFormData);
    const [lmias, setLmias] = useState<ISelectDropdownOption[]>([]);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isFileProcessing, setIsFileProcessing] = useState(false);
    const [apiError, setApiError] = useState<string | null>(null);
    const [signedUrl, setSignedUrl] = useState<string | null>(null);
    const [showPreview, setShowPreview] = useState(false);
    const [cancelParse, setCancelParse] = useState<CancelTokenSource | null>(null);

    const { mutate: parseDocument } = useParseDocument();
    const { mutate: getPreSignedUrl } = useGetPreSignedUrl();
    const { mutate: deleteDocument } = useDeleteDocument();
    const { mutate: getDocumentExpiryNotifications } = useGetDocumentExpiryNotification();
    const { mutate: deletePermit } = useDeletePermit();

    const getPermitTypeFromId = (id: number): IPermitType | undefined => {
        return permitTypes?.find(type => type.id === id);
    };

    const getFormData = () => {
        if (permitData) {
            const permitDetail = permitData.permit_details[0];
            const passport = passportsData.find(passport => passport.id === permitData.passport_id);
            const permitType = getPermitTypeFromId(permitDetail.permit_type_id);
            const submissionDate = permitDetail.submission_date ? getDateString(permitDetail.submission_date) : null;
            const issueDate = permitDetail.issued ? getDateString(permitDetail.issued) : null;
            const expiryDate = permitDetail.expire ? getDateString(permitDetail.expire) : null;
            const refusedDate = permitDetail.refused_date ? getDateString(permitDetail.refused_date) : null;
            const lmia = lmiasRes?.find(lmia => lmia.id === permitDetail.lmia_permit?.lmia_id);
            const status = OPDocumentStatuses.find(status => status.value === permitDetail.status);

            return {
                id: permitData.id,
                document_id: { value: permitDetail.document_id, error: '' },
                passport: { value: { value: passport?.id.toString() || '', label: passport?.passport_details[0]?.passport_number || '' }, error: '' },
                permitNumber: { value: permitDetail.permit_number || '', error: '' },
                permitType: { value: { value: permitType?.id.toString() || '', label: permitType?.description || '' }, error: '' },
                lmia: { value: { value: lmia?.id.toString() || '', label: lmia?.lmia_number && lmia?.title ? `${lmia.lmia_number} - ${lmia.title}` : "" }, error: '' },
                caseType: { value: permitDetail.case_type || '', error: '' },
                company: { value: permitDetail.company || '', error: '' },
                submissionDate: { value: submissionDate || '', error: '' },
                issueDate: { value: issueDate || '', error: '' },
                expiryDate: { value: expiryDate || '', error: '' },
                refusedDate: { value: refusedDate || '', error: '' },
                status: { value: { value: status?.value || '', label: status?.label || '' }, error: '' },
                document: permitDetail.document || null,
            };
        } else {
            return initialFormData;
        }
    }

    const hasGlobalUnsavedChanges = () => {
        return Object.entries(hasUnsavedData).some(([key, value]) => Number(key) !== index && value);
    }

    const onDiscardChanges = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();

        if (isDataChanged() && !hasGlobalUnsavedChanges())
            setFormData(getFormData());
    }

    const onChangeText = (name: PermitField, value: string) => {
        setFormData({ ...formData, [name]: { value, error: "" } });
    }

    const onChangeSelect = (name: PermitField, option: ISelectDropdownOption) => {
        setFormData({ ...formData, [name]: { value: option, error: "" } });
    }

    const isDataChanged = () => {
        const originalData = getFormData();

        return !isEqual(originalData, formData);
    }

    const isDisabled = (reason: string | null) => {
        const emptyFields = Object.keys(formData).some(field => {
            return requiredFields.some(field => {
                let value = "";
                if (selectFields.includes(field)) {
                    const dataValue = formData[field].value as ISelectDropdownOption;
                    value = dataValue.value;
                } else {
                    value = formData[field].value as string;
                }
                return !value?.trim()?.length;
            });
        });

        let companyCheck = false;
        const permitType = getPermitTypeFromId(Number(formData.permitType.value.value));
        if (permitType?.type === 'closed' && !formData.company.value.trim().length) {
            companyCheck = true;
        }

        const commonDisabled = !worker_id || linked || companyCheck || isLoading || isProcessing || isFileProcessing || hasGlobalUnsavedChanges();

        if (reason === 'save') {
            return emptyFields || commonDisabled || !isDataChanged();
        }

        return commonDisabled;
    }

    const onDeletePermit = async () => {
        if (!worker_id) return;
        if (formData.id) {
            deletePermit({
                foreignWorkerId: Number(worker_id),
                payload: { permitId: Number(formData.id) },
                setIsProcessing,
                onSuccess: () => {
                    setIsGlobalLoading({ [index]: false });
                    setHasUnsavedData({ [index]: false });
                    setPermitDatas(prevData => prevData.filter((_, i) => i !== index));
                }
            });
        } else {
            setIsGlobalLoading({ [index]: false });
            setHasUnsavedData({ [index]: false });
            setPermitDatas(prevData => prevData.filter((_, i) => i !== index));
        }
    }

    const isOkToDelete = () => {
        return !linked && !hasGlobalUnsavedChanges() && (formData.passport.value.value?.length ||
            formData.permitNumber.value?.length ||
            formData.lmia.value.value?.length ||
            formData.permitType.value.value?.length ||
            formData.caseType.value?.length ||
            formData.company.value?.length ||
            formData.submissionDate.value?.length ||
            formData.issueDate.value?.length ||
            formData.expiryDate.value?.length ||
            formData.status.value.value?.length);
    }

    const onConfirmDelete = (e: React.MouseEvent) => {
        e.preventDefault();

        if (isProcessing || !isOkToDelete()) return;

        dispatch(openModal({
            name: 'MESSAGE_MODAL',
            data: {
                view: 'CONFIRMATION',
                closeBtn: true,
                title: "Confirm delete?",
                content: "Do you want to delete this permit? This action is irreversible.",
                actionTxt: "Confirm",
                action: async () => {
                    await onDeletePermit();
                    dispatch(closeModal('MESSAGE_MODAL'));
                },
            }
        }))

    }

    const onSaveData = () => {
        if (isDisabled('save')) return;

        setApiError(null);

        setFormData(prevData => {
            return Object.keys(prevData).reduce((acc, key) => {
                if (key === 'id') return { ...acc, id: prevData.id };
                if (key === 'document') return { ...acc, document: prevData.document };
                return { ...acc, [key]: { value: prevData[key as PermitField].value, error: "" } };
            }, {} as IPermitData);
        });

        setIsProcessing(true);

        const genericDocument: IGenericDocument = {
            foreign_worker_id: worker_id ? Number(worker_id) : 0,
            documentMetadata: [
                {
                    id: formData.id ?? 0,
                    type: 'permit',
                    doc_number: formData.permitNumber.value ?? '',
                    expiry_date: formData.expiryDate.value ? moment.utc(formData.expiryDate.value).toDate() : null,
                    issue_date: formData.issueDate.value ? moment.utc(formData.issueDate.value).toDate() : null,
                }
            ]
        }

        const permitType = getPermitTypeFromId(Number(formData.permitType.value.value));
        const payload = {
            id: formData.id,
            document_id: formData.document_id.value ? formData.document_id.value : null,
            passport_id: Number(formData.passport.value.value),
            permit_type_id: Number(formData.permitType.value.value),
            permit_number: formData.permitNumber.value,
            lmia_id: formData.lmia.value.value?.length && permitType?.type === 'closed' ? Number(formData.lmia.value.value) : null,
            case_type: formData.caseType.value,
            company: permitType?.type === 'closed' && formData.company.value.trim().length ? formData.company.value : null,
            submission_date: formData.status.value.value !== 'approved' && formData.submissionDate.value.length ? moment.utc(formData.submissionDate.value).toDate() : null,
            issued: formData.status.value.value === 'approved' && formData.issueDate.value.length ? moment.utc(formData.issueDate.value).toDate() : null,
            expire: formData.status.value.value === 'approved' && formData.expiryDate.value.length ? moment.utc(formData.expiryDate.value).toDate() : null,
            refused_date: formData.status.value.value === 'refused' && formData.refusedDate.value.length ? moment.utc(formData.refusedDate.value).toDate() : null,
            status: formData.status.value.value as TDocStatus,
        };

        getDocumentExpiryNotifications({
            payload: genericDocument,
            isProcessing,
            setIsProcessing,
            documentType: 'permit',
            documentPayload: payload,
        });
    }

    const onGetPreSignedUrl = (isDownload = false) => {
        if (!formData.document?.id) return;

        getPreSignedUrl({
            workerId: worker_id ? Number(worker_id) : 0,
            documentId: formData.document.id ?? 0,
            documentType: 'permit',
            fileName: formData.document.original,
            expiresIn: 3600,
            setPresignedUrl: setSignedUrl,
            isDownload
        });
    }

    const onFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (linked || hasGlobalUnsavedChanges()) return;
        const file = e.target.files?.[0];
        e.target.value = '';

        if (file) {
            parseDocument({
                type: "permit",
                data: file,
                setIsProcessing: setIsFileProcessing,
                setApiError,
                docId: formData.id ?? 0,
                foreignWorkerId: Number(worker_id),
                passports: passportsData,
                lmias: lmiasRes || null,
                permitTypes: permitTypes || null,
                setCancelToken: setCancelParse,
                setFormData,
                isDataChanged,
                setPresignedUrl: setSignedUrl,
            }, {
                onSuccess: () => {
                    setShowPreview(true);
                }
            });
        }
    }

    const onRemoveFile = () => {
        if (linked || hasGlobalUnsavedChanges()) return;
        dispatch(openModal({
            name: 'MESSAGE_MODAL',
            data: {
                view: 'CONFIRMATION',
                closeBtn: true,
                title: "Remove Upload?",
                content: "Do you want to remove this permit document? You cannot undo this action.",
                actionTxt: "Confirm",
                action: () => {
                    deleteDocument({
                        workerId: worker_id ? Number(worker_id) : 0,
                        documentId: formData.document_id.value ?? 0,
                        type: 'permit',
                    }, {
                        onSuccess: () => {
                            setFormData(prevData => {
                                return {
                                    ...prevData,
                                    document_id: { value: 0, error: "" },
                                    document: null,
                                }
                            });

                            isDataChanged();
                        },
                    });

                    dispatch(closeModal('MESSAGE_MODAL'));
                },
            }
        }));
    };

    const onPreviewFile = () => {
        setShowPreview(true);
        onGetPreSignedUrl();
    };

    const onMinimizePreview = () => {
        setSignedUrl(null);
        setShowPreview(false);
    };

    const onDownloadFile = async () => {
        onGetPreSignedUrl(true);
    };

    useEffect(() => {
        if (lmiasRes?.length) {
            const newData: ISelectDropdownOption[] = [];

            for (let i = 0; i < lmiasRes.length; i++) {
                const lmiaRes = lmiasRes[i];
                const eLMIA = newData.find(lmia => lmia.value === lmiaRes.id.toString())
                if (!eLMIA) {
                    newData.push({ value: lmiaRes.id.toString(), label: `${lmiaRes.status === 'pending' ? '(Pending) ' : ''}${lmiaRes.lmia_number} - ${lmiaRes.title}` })
                };
            };

            setLmias(newData);
        };
    }, [lmiasRes]) // eslint-disable-line

    useEffect(() => {
        setFormData(getFormData());
    }, [permitData, permitTypes, lmiasRes, passportsData]) // eslint-disable-line

    useEffect(() => {
        if (isProcessing || isFileProcessing || isLoading) {
            setIsGlobalLoading({ [index]: true });
        } else {
            setIsGlobalLoading({ [index]: false });
        }
    }, [isFileProcessing, isLoading, isProcessing, index]); // eslint-disable-line

    useEffect(() => {
        if (isDataChanged()) {
            setHasUnsavedData({ [index]: true });
        } else {
            setHasUnsavedData({ [index]: false });
        }
    }, [formData, index]) // eslint-disable-line

    return (
        <>
            <div className='immployer__details_form_inputs__container__deletable_forms__with__preview'>
                <div className="immployer__details_form_inputs__container__deletable_forms">
                    <UploadFileBtn
                        linked={linked || hasGlobalUnsavedChanges()}
                        docType='Permit'
                        document={formData.document}
                        isProcessing={isFileProcessing}
                        apiError={apiError}
                        cancelParse={cancelParse}
                        onFileChange={onFileChange}
                        onRemoveFile={onRemoveFile}
                        onPreviewFile={onPreviewFile}
                    />

                    <div className="immployer__details_form_inputs">
                        <SelectDropdown
                            name="passport"
                            label="Linked Passport"
                            required={true}
                            placeholder="-- select passport --"
                            value={formData.passport.value}
                            onChange={(option: ISelectDropdownOption) => onChangeSelect("passport", option)}
                            errorMsg={formData.passport.error}
                            options={!passportsData?.length ? [] : passportsData?.map(passport => ({ value: passport.id.toString(), label: passport.passport_details[0].passport_number }))}
                            read_only={linked || isLoading || hasGlobalUnsavedChanges() || !passportsData?.length}
                        />
                        <SelectDropdown
                            name="status"
                            label="Status"
                            placeholder="-- select status --"
                            value={formData.status.value}
                            required={true}
                            onChange={(option: ISelectDropdownOption) => onChangeSelect("status", option)}
                            errorMsg={formData.status.error}
                            options={OPDocumentStatuses}
                            read_only={linked || isLoading || hasGlobalUnsavedChanges()}
                        />
                        <TextInput
                            name="permitNumber"
                            type="text"
                            label="Number"
                            required={true}
                            placeholder="Enter permit number"
                            pattern_mask="doc_number"
                            value={formData.permitNumber.value}
                            onChange={(value: string) => onChangeText("permitNumber", value)}
                            errorMsg={formData.permitNumber.error}
                            read_only={linked || isLoading || hasGlobalUnsavedChanges()}
                        />
                        <SelectDropdown
                            name="permitType"
                            label="Type"
                            required={true}
                            placeholder="-- select type --"
                            value={formData.permitType.value}
                            onChange={(option: ISelectDropdownOption) => onChangeSelect("permitType", option)}
                            errorMsg={formData.permitType.error}
                            options={!permitTypes?.length ? [] : permitTypes?.map(type => ({ value: type.id.toString(), label: type.description }))}
                            read_only={linked || isLoading || hasGlobalUnsavedChanges() || isPermitTypesLoading || !permitTypes?.length}
                        />
                        {
                            getPermitTypeFromId(Number(formData.permitType.value.value))?.type === 'closed'
                                ? <SelectDropdown
                                    name="lmia"
                                    label="LMIA"
                                    placeholder="-- select LMIA --"
                                    value={formData.lmia.value}
                                    onChange={(option: ISelectDropdownOption) => onChangeSelect("lmia", option)}
                                    errorMsg={formData.lmia.error}
                                    options={lmias}
                                    read_only={linked || isLoading || hasGlobalUnsavedChanges()}
                                    addNewBtnData={{
                                        name: "LMIA",
                                        label: "Add LMIA",
                                        action: () => navigate(`/employers`),
                                    }}
                                />
                                : <TextInput
                                    name="lmia"
                                    type="text"
                                    label="LMIA"
                                    placeholder="LMIA not required"
                                    value="LMIA not required"
                                    onChange={() => { }}
                                    errorMsg=""
                                    read_only={true}
                                />
                        }
                        <TextInput
                            name="company"
                            type="text"
                            label="Company Name"
                            placeholder="Enter company name"
                            required={getPermitTypeFromId(Number(formData.permitType.value.value))?.type === 'closed'}
                            value={formData.company.value}
                            onChange={(value: string) => onChangeText("company", value)}
                            errorMsg={formData.company.error}
                            read_only={linked || isLoading || hasGlobalUnsavedChanges() || getPermitTypeFromId(Number(formData.permitType.value.value))?.type !== 'closed'}
                        />
                        <TextInput
                            name="caseType"
                            type="text"
                            label="Case Type"
                            required={true}
                            placeholder="Enter case type"
                            value={formData.caseType.value}
                            onChange={(value: string) => onChangeText("caseType", value)}
                            errorMsg={formData.caseType.error}
                            read_only={linked || isLoading || hasGlobalUnsavedChanges()}
                        />
                        <CalenderInput
                            name="submissionDate"
                            label="Submission Date"
                            errorMsg={formData.submissionDate.error}
                            placeholder="mm/dd/yyyy"
                            format="mm/dd/yyyy"
                            onChange={(value: string) => onChangeText("submissionDate", value)}
                            value={formData.submissionDate.value}
                            read_only={linked || isLoading || hasGlobalUnsavedChanges() || formData.status.value.value === 'approved'}
                        />
                        <CalenderInput
                            name="refusedDate"
                            label="Decision Date"
                            errorMsg={formData.refusedDate.error}
                            placeholder="mm/dd/yyyy"
                            format="mm/dd/yyyy"
                            onChange={(value: string) => onChangeText("refusedDate", value)}
                            value={formData.refusedDate.value}
                            read_only={linked || isLoading || hasGlobalUnsavedChanges() || formData.status.value.value !== 'refused'}
                        />
                        <CalenderInput
                            name="issueDate"
                            label="Issue Date"
                            errorMsg={formData.issueDate.error}
                            placeholder="mm/dd/yyyy"
                            format="mm/dd/yyyy"
                            onChange={(value: string) => onChangeText("issueDate", value)}
                            value={formData.issueDate.value}
                            read_only={linked || isLoading || hasGlobalUnsavedChanges() || formData.status.value.value !== 'approved'}
                        />
                        <CalenderInput
                            name="expiryDate"
                            label="Expiry Date"
                            errorMsg={formData.expiryDate.error}
                            placeholder="mm/dd/yyyy"
                            format="mm/dd/yyyy"
                            onChange={(value: string) => onChangeText("expiryDate", value)}
                            value={formData.expiryDate.value}
                            read_only={linked || isLoading || hasGlobalUnsavedChanges() || formData.status.value.value !== 'approved'}
                        />
                    </div>
                </div>
                {formData.document && signedUrl && showPreview &&
                    <PreviewPanel
                        linked={linked || hasGlobalUnsavedChanges()}
                        fileName={formData.document.original}
                        src={signedUrl}
                        onDownloadFile={onDownloadFile}
                        onRemoveFile={onRemoveFile}
                        onMinimizePreview={onMinimizePreview}
                    />
                }
            </div>
            <ImmigrationFormAction
                docType="Permit"
                isProcessing={isProcessing}
                isDataChanged={isDataChanged || hasGlobalUnsavedChanges()}
                isDisabled={isDisabled}
                onDiscardChanges={onDiscardChanges}
                onConfirmDelete={onConfirmDelete}
                onSaveData={onSaveData}
                isOkToDelete={isOkToDelete}
            />
        </>
    )
}

export default PermitForm;