import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import moment from 'moment';

import { openModal } from '../../../../store/slices/modal';

import useGetForeignWorkerApplicantScores from '../../../../hooks/foreign_workers/useGetForeignWorkerApplicantScores';
import useGetForeignWorkerApplicantPrograms from '../../../../hooks/foreign_workers/useGetForeignWorkerApplicantPrograms';
import useGetForeignWorkerApplicantScoreHypoGroups from '../../../../hooks/foreign_workers/useGetForeignWorkerApplicantScoreHypoGroups';

import { TCategoryGroup } from '../../../../interfaces/entities.types';
import { IProgramsDropdownOption } from '../../../../interfaces/formElements';
import { IApplicantProgram, IApplicantScoreHypoGroup, ITag } from '../../../../interfaces/services';

import { Icons } from '../../../../data/Assets';

import ScoreHypoCard from './ScoreHypoCard';
import OtherProgramCard from './OtherProgramCard';
import ProgramsDropDown from './ProgramsDropDown';
import { SVGIcon } from '../../../wrappers/SVGIcon';

interface IPROptionsProps {
    workerName: string;
    linked: boolean;
};

const PROptions = (props: IPROptionsProps) => {
    const { workerName, linked } = props;
    const { worker_id } = useParams<{ worker_id: string }>();
    const dispatch = useDispatch();

    const categoryGroupOptions: IProgramsDropdownOption[] = [
        {
            value: 'express-entry',
            label: 'Express Entry'
        },
        {
            value: 'other-pr',
            label: 'Other PR'
        },
        {
            value: 'temporary',
            label: 'Temporary'
        }
    ]

    const [chosenCategoryGroup, setChosenCategoryGroup] = useState<IProgramsDropdownOption>(categoryGroupOptions[0]);
    const [chosenProgram, setChosenProgram] = useState<IApplicantProgram | null>(null);
    const [programs, setPrograms] = useState<IApplicantProgram[]>([]);
    const [scoreHypos, setScoreHypos] = useState<IApplicantScoreHypoGroup[]>([]);
    const [score, setScore] = useState(0);
    const [totalScore, setTotalScore] = useState(0);
    const [filters, setFilters] = useState<ITag[]>([]);
    const [selectedFilters, setSelectedFilters] = useState<ITag[]>([]);

    const { data: applicantScores } = useGetForeignWorkerApplicantScores(worker_id || '0', linked);
    const { data: applicantPrograms } = useGetForeignWorkerApplicantPrograms({
        foreign_worker_id: parseInt(worker_id || '0'),
        category_group: chosenCategoryGroup.value as TCategoryGroup
    }, linked);
    const { data: scoreHypoGroups } = useGetForeignWorkerApplicantScoreHypoGroups(worker_id || '0', linked);


    const onChangeSelect = (dropDownType: 'category_group' | 'program', option: IProgramsDropdownOption) => {
        if (dropDownType === 'category_group') {
            setChosenCategoryGroup(option);
        } else if (dropDownType === 'program') {
            setChosenProgram(programs.find(program => program.id.toString() === option.value) || null);
        }
    }

    const getDifficulty = (score: number, totalScore: number) => {
        if (score >= totalScore)
            return 'easy';
        else if (totalScore - score <= 100)
            return 'medium';
        else
            return 'hard';
    }

    useEffect(() => {
        if (chosenCategoryGroup.value === 'other-pr') {
            setFilters([{ id: 0, name: "Favourite" }, ...(applicantPrograms?.main_program_filters || [])]);
            setSelectedFilters([]);
        }

        if (applicantPrograms?.main?.length) {
            setPrograms(applicantPrograms.main);
            setChosenProgram(applicantPrograms.main[0]);
        };
    }, [applicantPrograms, chosenCategoryGroup]);

    useEffect(() => {
        if (applicantScores?.main?.length && chosenProgram?.results?.length) {
            setScore(applicantScores.main[0].score);
            setTotalScore(chosenProgram.results[0].draw_points);
        };
    }, [chosenProgram, applicantScores]);

    useEffect(() => {
        if (chosenCategoryGroup.value === 'express-entry') {
            setFilters([{ id: 0, name: "Favourite" }, ...(scoreHypoGroups?.main_filters || [])]);
            setSelectedFilters([]);
        }

        if (score >= totalScore) {
            setScoreHypos([]);
        } else if (scoreHypoGroups?.main?.length) {
            setScoreHypos(scoreHypoGroups.main.filter(scoreHypo => scoreHypo.score >= totalScore));
        };
    }, [scoreHypoGroups, chosenCategoryGroup, chosenProgram, score, totalScore]);

    const onFilterButtonClick = () => {
        dispatch(openModal({
            name: 'FILTER_MODAL',
            data: {
                view: 'FILTER',
                filterText: chosenCategoryGroup.value === 'express-entry' ? 'Filter Recommendations' : 'Filter Programs',
                filters: filters,
                selectedFilters: selectedFilters,
                setSelectedFilters: setSelectedFilters,
            }
        }));
    };

    const getFilteredScoreHypos = useCallback(() => {
        const filteredScoreHypos = scoreHypos.filter(item => {
            const isFavoriteFilter = selectedFilters.find(tag => tag.id === 0) ? item.favorite : true;
            const isProgramMatch = selectedFilters.length ? selectedFilters.filter(filterTag => filterTag.id !== 0).every(filterTag => item.tag.find(tag => tag.id === filterTag.id) !== undefined) : true;

            const isFilterMatch = isFavoriteFilter && isProgramMatch;

            return isFilterMatch;
        });

        return filteredScoreHypos;
    }, [selectedFilters, scoreHypos]);

    const getFilteredPrograms = useCallback(() => {
        const filteredPrograms = programs.filter(item => {
            const isFavoriteFilter = selectedFilters.find(tag => tag.id === 0) ? item.favorite : true;
            const isProgramMatch = selectedFilters.length ? selectedFilters.filter(filterTag => filterTag.id !== 0).every(filterTag => item.program_tags.find(programTag => programTag.tag_id === filterTag.id) !== undefined) : true;

            const isFilterMatch = isFavoriteFilter && isProgramMatch;

            return isFilterMatch;
        });

        return filteredPrograms;
    }, [selectedFilters, programs]);

    const scoreHyposFiltered = getFilteredScoreHypos();
    const programsFiltered = getFilteredPrograms();

    return (
        <div className='immployer__details_pr_options__container'>
            {linked
                ? <>
                    <div className='immployer__pr_options__score__container'>
                        <h3 className='immployer__pr_options__score__container__header_title'>
                            Chances of getting PR
                        </h3>

                        <ProgramsDropDown
                            value={chosenCategoryGroup}
                            options={categoryGroupOptions}
                            onChangeSelect={(option) => onChangeSelect('category_group', option)}
                        />

                        <div className='immployer__pr_options__score'>
                            <h5 className='immployer__pr_options__score__worker_name'>
                                {workerName}'s Score
                            </h5>

                            <div className='immployer__pr_options__score_points'>
                                <h3 className={`immployer__pr_options__score_points__value immployer__score_${getDifficulty(score, totalScore)}_color`}>
                                    {score} points
                                </h3>
                            </div>

                            {chosenCategoryGroup.value === 'express-entry' &&
                                <>
                                    {programs.length > 0 &&
                                        <ProgramsDropDown
                                            value={{
                                                value: chosenProgram?.id.toString() || '',
                                                label: chosenProgram?.name || '',
                                                difficulty: getDifficulty(score, chosenProgram?.results[0].draw_points || 0)
                                            }}
                                            options={programs.map(program => ({
                                                value: program.id.toString(),
                                                label: program.name,
                                                difficulty: getDifficulty(score, program.results[0].draw_points || 0)
                                            }))}
                                            onChangeSelect={(option) => onChangeSelect('program', option)}
                                        />
                                    }

                                    <hr className='immployer__pr_options__score_divider' />

                                    {chosenProgram &&
                                        <div className='immployer__pr_options__program_details'>
                                            <div className='immployer__pr_options__program_details__header'>
                                                <h5 className='immployer__pr_options__draws_header_title'>
                                                    {chosenProgram.results[0].draw_date
                                                        ? `Recent draw ${moment.utc(chosenProgram.results[0].draw_date).format('MMMM DD, YYYY')}`
                                                        : 'No recent draws'
                                                    }
                                                </h5>

                                                <h5 className='immployer__pr_options__draws_points'>
                                                    {chosenProgram.results[0].draw_points} points
                                                </h5>
                                            </div>

                                            <div className='immployer__pr_options__program_details__content'>
                                                <h5>
                                                    {chosenProgram.description}
                                                </h5>
                                            </div>
                                        </div>
                                    }
                                </>
                            }
                        </div>
                    </div>

                    <div className='immployer__pr_options__score__recommendations__container'>
                        <div className='immployer__pr_options__recommendations__header'>
                            <span className='immployer__pr_options__recommendations__header_title'>
                                {chosenCategoryGroup.value === 'express-entry'
                                    ? 'Recommendations'
                                    : `${chosenCategoryGroup.label} Programs`
                                }
                            </span>

                            <button className='immployer__pr_options__recommendations__filter_btn' onClick={onFilterButtonClick}>
                                <SVGIcon src={Icons.Filter} color='primary' />
                                <h5>Filter</h5>
                            </button>
                        </div>

                        <div className='immployer__pr_options__recommendations_list'>
                            {chosenCategoryGroup.value === 'express-entry' &&
                                <>
                                    {scoreHyposFiltered.length === 0
                                        ? <div className='immployer__pr_options__no_recommendations'>
                                            <span> No recommendations available.
                                                {chosenCategoryGroup.value === 'express-entry'
                                                    && getDifficulty(score, totalScore) === 'easy'
                                                    ? 'You have a good chance of getting PR.'
                                                    : ''
                                                }
                                            </span>
                                        </div>
                                        : scoreHyposFiltered.map((scoreHypo, index) => (
                                            <ScoreHypoCard
                                                key={index}
                                                scoreHypoGroup={scoreHypo}
                                            />
                                        ))
                                    }
                                </>
                            }
                            {chosenCategoryGroup.value === 'other-pr' &&
                                <>
                                    {programsFiltered.length === 0
                                        ? <div className='immployer__pr_options__no_recommendations'>
                                            <span> No recommendations available. </span>
                                        </div>
                                        : programsFiltered.map((program, index) => (
                                            <OtherProgramCard
                                                key={index}
                                                program={program}
                                            />
                                        ))
                                    }
                                </>
                            }
                            {chosenCategoryGroup.value === 'temporary' &&
                                <div className='immployer__pr_options__no_recommendations'>
                                    <span> No recommendations available. </span>
                                </div>
                            }
                        </div>
                    </div>
                </>
                : <div className='immployer__pr_options__score__container'>
                    <h3 className='immployer__pr_options__score__container__header_title'>
                        Please link the worker to view PR options
                    </h3>
                </div>
            }

        </div >
    )
}

export default PROptions;