import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { IInvitationViewProps, IUnlinkForeignWorkerViewProps, IConfimationViewProps, INotificationViewProps, IWarningViewProps } from "../../../interfaces/modals";

import { Icons } from "../../../data/Assets";

import useInviteForeignWorker from "../../../hooks/invitations/useInviteForeignWorker";
import useUnlinkForeignWorker from "../../../hooks/foreign_workers/useUnlinkForeignWorker";
import useSavePassport from "../../../hooks/passports/useSavePassport";
import useSavePermit from "../../../hooks/permits/useSavePermit";
import useSaveVisa from "../../../hooks/visas/useSaveVisa";
import useGetWorkerImmigrationDocs from "../../../hooks/foreign_workers/useGetWorkerImmigrationDocs";
import useSaveForeignWorker from "../../../hooks/foreign_workers/useSaveForeignWorker";

import { SVGIcon } from "../../wrappers/SVGIcon";

export const ConfirmationView = (props: IConfimationViewProps) => {
    const { title, content, onClose, cancelFn, cancelTxt, action, actionTxt } = props;

    const onCancel = () => {
        if (cancelFn) cancelFn();
        onClose();
    };

    return (
        <div className="immployer__message_modal__confirmation_view">
            <br />
            <h4 className="color_dark">{title}</h4>
            <p className="color_dark_grey">{content}</p>
            <br />
            <div className="immployer__message_modal__confirmation_actions">
                <button className="immployer__btn immployer__btn__secondary" onClick={onCancel}>{cancelTxt}</button>
                <button
                    className='immployer__btn immployer__btn__primary'
                    onClick={action}
                >
                    {actionTxt}
                </button>
            </div>
        </div>
    );
};

export const InvitationView = (props: IInvitationViewProps) => {
    const navigate = useNavigate();

    const { foreignWorkerId, onClose } = props;

    const [isProcessing, setIsProcessing] = useState<boolean>(false);
    const { data: docs, isLoading: isDocsLoading } = useGetWorkerImmigrationDocs(foreignWorkerId ? foreignWorkerId.toString() : undefined, undefined);

    const { passports, permits, visas } = docs ?? {};

    const { mutate: inviteForeignWorker } = useInviteForeignWorker();

    const hasDocuments = () => {
        return passports?.length || permits?.length || visas?.length;
    };

    const onSendInvitation = () => {
        if (!foreignWorkerId) return;

        setIsProcessing(true);

        inviteForeignWorker({
            foreignWorkerId,
            setIsProcessing,
            onClose,
        });
    };

    const onGoToImmigration = () => {
        onClose();
        navigate(`/foreign-workers/${foreignWorkerId}/immigration`)
    };

    return (
        <div className="immployer__message_modal__invitation_view">
            <br />
            <h4 className="color_dark">Link Foreign Worker</h4>
            <p className="color_dark_grey pt_10">
                We will email the worker, inviting them to the system.
            </p>
            <p className="color_dark_grey pt_10">
                The worker can edit information and upload documents, which you, the employer, can view.
            </p>
            <br />
            <div className="immployer__message_modal__invitation_warning_content">
                <SVGIcon src={Icons.ExclamationTriangle} color='error' />
                <p className="color_error">
                    You won’t be able to change their immigration information after they accept.
                </p>
            </div>
            <br />
            <div className="immployer__message_modal__invitation_warning_content">
                {!hasDocuments() && <SVGIcon src={Icons.ExclamationTriangle} color='error' />}
                <p className={hasDocuments() ? "color_dark_grey" : 'color_error'}>
                    {
                        hasDocuments()
                            ? 'Before submitting the request, you can review any immigration-related information (if applicable).'
                            : 'You have not added any immigration documents for this foreign worker. Are you sure you want to send an invitation?'
                    }
                </p>
            </div>
            <br />
            <div className="immployer__message_modal__invitation_actions">
                <button className="immployer__btn immployer__btn__secondary" onClick={onGoToImmigration}>{hasDocuments() ? 'Verify Details' : 'Complete Immigration Info'}</button>
                <button
                    className={`immployer__btn immployer__btn__primary ${!foreignWorkerId ? 'immployer__btn__primary__disabled' : ''}`}
                    onClick={onSendInvitation}
                >
                    {
                        isProcessing || isDocsLoading
                            ? <i className='immployer__btn_loader' />
                            : 'Send Invitation'
                    }
                </button>
            </div>
        </div>
    );
};

export const UnlinkForeignWorkerView = (props: IUnlinkForeignWorkerViewProps) => {
    const { foreignWorkerId, onClose } = props;

    const [isProcessing, setIsProcessing] = useState<boolean>(false);

    const { mutate: unlinkForeignWorker } = useUnlinkForeignWorker();

    const onUnlinkForeignWorker = () => {
        if (!foreignWorkerId) return;

        setIsProcessing(true);

        unlinkForeignWorker({
            foreignWorkerId,
            setIsProcessing,
            onClose,
        });
    };

    return (
        <div className="immployer__message_modal__invitation_view">
            <br />
            <h4 className="color_dark">Unlink Foreign Worker</h4>
            <p className="color_dark_grey">
                Foreign Worker will be unlinked from your dashboard.
                You can link them again by sending an invitation.
            </p>
            <br />
            <div className="immployer__message_modal__invitation_warning_content">
                <SVGIcon src={Icons.ExclamationTriangle} color='error' />
                <p className="color_error">
                    If you proceed, you as the employer will be able to edit their immigration related information.
                    These updates won't be reflected in their account.{" "}
                    <Link to={'/'} className="display_inline">Learn more</Link>
                </p>
            </div>
            <br />
            <div className="immployer__message_modal__invitation_actions">
                <button className="immployer__btn immployer__btn__secondary" onClick={onClose}>Discard</button>
                <button
                    className={`immployer__btn immployer__btn__primary ${!foreignWorkerId ? 'immployer__btn__primary__disabled' : ''}`}
                    onClick={onUnlinkForeignWorker}
                >
                    {
                        isProcessing
                            ? <i className='immployer__btn_loader' />
                            : 'Unlink Foreign Worker'
                    }
                </button>
            </div>
        </div>
    );
};

export const NotificationView = (props: INotificationViewProps) => {
    const { notifications, isProcessing, setIsProcessing, documentType, documentPayload, operation, onClose } = props;

    const { mutate: savePassport } = useSavePassport();
    const { mutate: savePermit } = useSavePermit();
    const { mutate: saveVisa } = useSaveVisa();
    const { mutate: saveForeignWorker } = useSaveForeignWorker();

    const handleSubmit = () => {
        switch (documentType) {
            case 'passport':
                savePassport({
                    payload: documentPayload,
                    setIsProcessing,
                });
                break;
            case 'permit':
                savePermit({
                    payload: documentPayload,
                    setIsProcessing,
                });
                break;
            case 'visa':
                saveVisa({
                    payload: documentPayload,
                    setIsProcessing,
                });
                break;

            case 'sin':
                saveForeignWorker({
                    payload: documentPayload,
                    operation: operation ?? 'create',
                    setIsProcessing,
                });
                break;
            default:
                break;
        };

        onClose();
    };

    return (
        <div className="immployer__message_modal__notification_view">
            <br />
            <h4 className="color_dark">Save Foreign Worker Information</h4>
            <p className="color_dark_grey">
                System will generate the following notifications upon performing this action:
            </p>
            <br />

            <div className="immployer__message_modal__notification_content">
                {notifications.map((notification, index) => (
                    <div key={index} className="immployer__message_modal__notification_item">
                        <p className="color_dark_grey"><SVGIcon src={Icons.PointFill} /> {notification.title}</p>
                        <span>{notification.description}</span>
                    </div>
                ))}
            </div>

            <br />
            <div className="immployer__message_modal__notification_actions">
                <button className="immployer__btn immployer__btn__secondary" onClick={onClose}>Go back</button>
                <button
                    className={`immployer__btn immployer__btn__primary`}
                    onClick={handleSubmit}
                >
                    {
                        isProcessing
                            ? <i className='immployer__btn_loader' />
                            : 'Proceed'
                    }
                </button>
            </div>
        </div>
    );
};

export const WarningView = (props: IWarningViewProps) => {
    const { title, subtitle, content, onClose, cancelFn, cancelTxt } = props;

    const onCancel = () => {
        if (cancelFn) cancelFn();
        onClose();
    };

    return (
        <div className="immployer__message_modal__error_view">
            <br />
            <h4 className="color_dark">{title}</h4>
            <br />
            <div className="immployer__message_modal__error_warning_content">
                <SVGIcon src={Icons.ExclamationTriangle} color='error' />
                <p className="color_error">
                    {subtitle}
                </p>
            </div>
            <br />
            <div className="immployer__message_modal__error_content">
                {content.map((item, index) => (
                    <div key={index} className="immployer__message_modal__error_item">
                        {content.length > 1 && <SVGIcon src={Icons.PointFill} />} <span className="color_dark_grey">{item}</span>
                    </div>
                ))}
            </div>
            <div className="immployer__message_modal__error_actions">
                <button
                    className={`immployer__btn immployer__btn__primary`}
                    onClick={onCancel}
                >
                    {cancelTxt}
                </button>
            </div>
        </div>
    )
};