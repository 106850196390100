import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { IGettingStartedCardProps, IBoxNoteProps, INotificationCardProps } from '../../interfaces/cards';

import useMarkNotificationRead from '../../hooks/notifications/useMarkNotificationRead';

import { getDateString } from '../../helpers/utility';

import { getLMIAApplicable, setLMIAApplicable } from '../../store/slices/global';

import { SVGIcon } from '../wrappers/SVGIcon';

import { Icons } from '../../data/Assets';

export const GettingStartedCard = (props: IGettingStartedCardProps) => {
    const { name, code, title, isComplete, action } = props;

    const dispatch = useDispatch();

    const LMIAApplicable = useSelector(getLMIAApplicable());

    const onClickLMIANA = () => {
        if (!LMIAApplicable) return;

        dispatch(setLMIAApplicable(false));
    };

    return (
        <div className={`immployer__getting_started_card immployer__getting_started_card__${code}`}>
            <h4 className='immployer__getting_started_card__title'>{title}</h4>
            <div className='immployer__getting_started_card__content'>
                {!isComplete &&
                    <div className="immployer__btn__not_completed">
                        <SVGIcon src={Icons.ExclamationTriangle} />
                        <span>Not Completed</span>
                    </div>
                }
                {(name !== 'lmia' || LMIAApplicable) &&
                    <button
                        onClick={action}
                        className={`btn_block immployer__btn immployer__btn__secondary ${isComplete ? "success_btn" : ""}`}
                    >
                        {isComplete ? "Completed" : "Complete now"}
                    </button>
                }
                {name === 'lmia' && !isComplete && code !== 'inactive' &&
                    <button
                        onClick={onClickLMIANA}
                        className={`btn_block immployer__btn immployer__btn__secondary ${!LMIAApplicable ? "success_btn" : ""}`}
                    >
                        Not Applicable
                    </button>
                }
            </div>
        </div>
    );
};

export const BoxNote = (props: IBoxNoteProps) => {
    const { code, title, content, maxWidth = '100%' } = props;

    return (
        <div className={`immployer__box_note immployer__box_note__${code}`} style={{ maxWidth: maxWidth }}>
            <div className='immployer__box_note__left_border' />
            <div className='immployer__box_note__content_box'>
                {!!title && <h6 className='immployer__box_note__title'>{title}</h6>}
                <div className='immployer__box_note__content'>
                    {content}
                </div>
            </div>
        </div>
    );
};

export const NotificationCard = (props: INotificationCardProps) => {
    const { id, title, descr, date, actions, criticalAction, isRead } = props;

    const navigate = useNavigate();

    const handleNavigateAction = (name: string, link: string) => {
        name = name.toLowerCase();
        if (name.includes('visa')) {
            navigate(link, {
                state: { tab: 'Visas' }
            })
        } else if (name.includes('permit')) {
            navigate(link, {
                state: { tab: 'Permits' }
            })
        } else {
            navigate(link);
        }
    };

    const { mutate: markNotificationAsRead } = useMarkNotificationRead();

    const onNotificationClick = () => {
        if (isRead) return;

        markNotificationAsRead({ notificationId: id });
    };

    return (
        <div className={`immployer__notification__card ${!isRead ? 'immployer__notification__card__unread' : ''}`} onClick={() => onNotificationClick()}>
            <div className='immployer__notification__card__content'>
                {criticalAction && <p className='immployer__notification__card__critical_action'>{criticalAction}</p>}
                <div className='immployer__notification__card__title_box'>
                    <div className='immployer__notification__card__read_status'>
                        {!isRead ? <span /> : null}
                    </div>
                    <p className='immployer__notification__card__title'>{title}</p>
                </div>
                <p className='immployer__notification__card__descr'>{descr}</p>
                <div className='immployer__notification__card__actions'>
                    {!!actions.length && actions.map((action, index) =>
                        <button
                            key={index}
                            className={`immployer__btn immployer__btn__${index & 1 ? 'secondary' : 'primary'}`}
                            onClick={() => action.type === 'navigate' ? handleNavigateAction(action.name, action.link) : null}
                        >
                            {action.name}
                        </button>
                    )}
                </div>
            </div>
            <div className="immployer__notification__card__right_box">
                <p className='immployer__notification__card__date'>{getDateString(date, 'MMM DD-YYYY')}</p>
            </div>
        </div>
    );
};