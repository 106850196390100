import React from "react";
import { useNavigate, useParams } from "react-router-dom";

import { IColumnProps, ILocationsListTableProps } from "../../../interfaces/tables";

import { Icons } from "../../../data/Assets";

import { getFormattedString, getPhoneNumberString } from "../../../helpers/utility";

import { SVGIcon } from "../../wrappers/SVGIcon";

const LocationsListTable = (props: ILocationsListTableProps) => {
    const { isLoading, locations, sortQuery, setSortQuery } = props;

    const { employer_id } = useParams();
    const navigate = useNavigate();

    const columns: IColumnProps[] = [
        { name: "Identifier", sort: true, identifier: 'identifier', buttonClass: 'btn_block' },
        { name: "Location Type", sort: true, identifier: 'type', buttonClass: 'btn_block' },
        { name: "Street Name", sort: true, identifier: 'street', buttonClass: 'btn_block' },
        { name: "City/Town", sort: true, identifier: 'city', buttonClass: 'btn_block' },
        { name: "Province", sort: true, identifier: 'province', buttonClass: 'btn_block' },
        { name: "Postal Code", sort: true, identifier: 'postal', buttonClass: 'btn_block' },
        { name: "Phone Number", sort: true, identifier: 'phone', buttonClass: 'btn_block' },
        { name: "Employees", sort: true, identifier: 'employees', buttonClass: 'btn_block' },
    ];

    const handleSort = (sort_key: string) => {
        setSortQuery({
            sort_key,
            sort_order: sortQuery.sort_order === 'ASC' ? 'DESC' : 'ASC'
        });
    }

    return (
        <div className="immployer__list_table__container">
            <table className="immployer__list_table">
                <thead>
                    <tr>
                        {columns.map((column, index) =>
                            <th key={index}>
                                <div>
                                    <button onClick={() => column.sort ? handleSort(column.identifier ?? 'first_name') : null} className={`${column.buttonClass ?? ''}`}>
                                        {column.name}
                                        {column?.sort && column.identifier === sortQuery.sort_key && <SVGIcon src={Icons.ArrowDownUp} color='primary' />}
                                    </button>
                                </div>
                            </th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {
                        isLoading
                            ? <tr>
                                <td colSpan={columns.length}>
                                    <div className="display_flex flex_dir_column display_flex_center">
                                        <span className="wt_600 pb_10">Loading</span>
                                        <i className='immployer__list_loader' />
                                    </div>
                                </td>
                            </tr>
                            : !locations?.length
                                ? <tr>
                                    <td colSpan={columns.length} className='text_center'>
                                        No records found.
                                    </td>
                                </tr>
                                : locations.map((location, index) =>
                                    <tr
                                        key={index}
                                        onClick={() => navigate(`/employers/${employer_id}/locations/${location.id}/details`)}
                                    >
                                        <td>{location.identifier}</td>
                                        <td>{getFormattedString(location.type, "title_case")}</td>
                                        <td>{location.street}</td>
                                        <td>{location.city}</td>
                                        <td>{location.province}</td>
                                        <td>{location.postal}</td>
                                        <td>{getPhoneNumberString(location.phone)}</td>
                                        <td>{location.full_time_emp + location.part_time_emp}</td>
                                    </tr>
                                )
                    }
                </tbody>
            </table>
        </div>
    );
};

export default LocationsListTable;