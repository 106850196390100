import { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";

import WorkerDetailsLayout from "../../../layouts/details/Worker";
import PassportsView from "../../../views/details_forms/foreign_worker/immigration/Passports";
import VisasView from "../../../views/details_forms/foreign_worker/immigration/Visas";
import PermitsView from "../../../views/details_forms/foreign_worker/immigration/Permits";

import useGetForeignWorkerDetails from "../../../../hooks/foreign_workers/useGetForeignWorkerDetails";
import useGetWorkerImmigrationDocs from "../../../../hooks/foreign_workers/useGetWorkerImmigrationDocs";
import useGetForeignWorkerInvitations from "../../../../hooks/invitations/useGetForeignWorkerInvitations";
import useGetWorkerJobs from "../../../../hooks/worker_jobs/useGetWorkerJobs";

import { ImmigrationTab } from "../../../../interfaces/foreign_worker";
import PROptions from "../../../sub_components/immigration/pr_options/PROptions";

const ForeignWorkerImmigrationDetailsPage = () => {
    const { worker_id } = useParams();
    const location = useLocation();

    const tabsList: ImmigrationTab[] = ['Passports', 'Permits', 'Visas', 'PR Options'];

    const [currentTab, setCurrentTab] = useState<ImmigrationTab>('Passports');

    const { data: worker } = useGetForeignWorkerDetails(worker_id);
    const { data: docs, isLoading, error } = useGetWorkerImmigrationDocs(worker_id, currentTab);
    const { data: invitations } = useGetForeignWorkerInvitations(worker_id);
    const { data: workerJobs } = useGetWorkerJobs(worker_id);

    const tab = location.state?.tab ?? null;
    useEffect(() => {
        if (tab) setCurrentTab(tab);
    }, [tab]);

    useEffect(() => {
        const container = document.querySelector('.immployer__common_details__sub_info__forms_container');
        if (container) container.scrollTo(0, 0);
    }, [currentTab]);

    return (
        <WorkerDetailsLayout
            goBackRoute={location.state?.goBackRoute ?? null}
            operation='update'
            currentTab="Immigration"
            title={worker?.worker_details?.length ? `${worker.worker_details[0].first_name} ${worker.worker_details[0].last_name}` : 'Worker Immigration'}
            error={!!error}
            invitations={invitations ?? []}
            linkedStatus={{
                isDataAvailable: !!worker?.worker_details[0],
                linked: !!worker?.worker_details[0]?.linked,
                linkedDate: worker?.worker_details[0]?.date_linked ?? null,
                unlinkedDate: worker?.worker_details[0]?.date_unlinked ?? null,
            }}
            isEmploymentAdded={!!workerJobs?.length}
        >
            <div className="immployer__common_details__sub_info_container">
                <div className='immployer__common_details__sub_info_view'>
                    <div className="immployer__tabs">
                        {tabsList.map((tabItem, index) =>
                            <button
                                key={index}
                                className={`immployer__tab ${currentTab === tabItem ? "immployer__tab__active" : ""} ${!docs?.passports?.length && tabItem !== "Passports" ? 'immployer__tab__disabled' : ''}`}
                                onClick={() => !docs?.passports?.length ? null : setCurrentTab(tabItem)}
                            >
                                {tabItem}
                            </button>
                        )}
                    </div>
                    <div className="immployer__common_details__sub_info__forms_container">
                        {currentTab === 'Passports' &&
                            <PassportsView
                                goBackRoute={location.state?.goBackRoute ?? null}
                                linked={!!worker?.worker_details[0]?.linked}
                                passportsData={docs?.passports?.length ? docs.passports : []}
                                visasData={docs?.visas?.length ? docs.visas : []}
                                permitsData={docs?.permits?.length ? docs.permits : []}
                                isLoading={isLoading}
                                setCurrentTab={setCurrentTab}
                            />
                        }
                        {currentTab === 'Permits' &&
                            <PermitsView
                                goBackRoute={location.state?.goBackRoute ?? null}
                                linked={!!worker?.worker_details[0]?.linked}
                                permitsData={docs?.permits?.length ? docs.permits : []}
                                passportsData={docs?.passports?.length ? docs.passports : []}
                                isLoading={isLoading}
                                setCurrentTab={setCurrentTab}
                            />
                        }
                        {currentTab === 'Visas' &&
                            <VisasView
                                goBackRoute={location.state?.goBackRoute ?? null}
                                linked={!!worker?.worker_details[0]?.linked}
                                visasData={docs?.visas?.length ? docs.visas : []}
                                passportsData={docs?.passports?.length ? docs.passports : []}
                                isLoading={isLoading}
                                setCurrentTab={setCurrentTab}
                            />
                        }
                        {
                            currentTab === 'PR Options' &&
                            <PROptions
                                linked={!!worker?.worker_details[0]?.linked}
                                workerName={`${worker?.worker_details[0]?.first_name ?? 'Worker'}`}
                            />
                        }
                    </div>
                </div>
                {/* {
                    showPreview && previewFile
                        ? <div className="immployer__common_details__document__view">
                            <div className="doc_preview__title">
                                <h5 className="color_grey">{previewFile.name || ''}</h5>
                                <button className="close_preview" onClick={() => setShowPreview(false)}><BsX /></button>
                            </div>
                            <div className="document__preview">
                                <Zoom
                                    zoomMargin={10}
                                >
                                    <ImageAsset src={imageSrc || ''} alt="preview" />
                                </Zoom>
                            </div>
                        </div>
                        : null
                } */}
            </div>
        </WorkerDetailsLayout>
    );
};

export default ForeignWorkerImmigrationDetailsPage;