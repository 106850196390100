import React from 'react'

import { Icons } from '../../../data/Assets';
import { SVGIcon } from '../../wrappers/SVGIcon';

interface IImmigrationFormActionProps {
    docType: 'Passport' | 'Permit' | 'Visa';
    isProcessing: boolean;
    isDisabled: (type: string) => boolean;
    isDataChanged: () => boolean
    onDiscardChanges: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onConfirmDelete: (e: any) => void;
    onSaveData: () => void;
    isOkToDelete: () => number | boolean;
};
const ImmigrationFormAction = (props: IImmigrationFormActionProps) => {
    const { docType, isProcessing, isDataChanged, isDisabled, onDiscardChanges, onConfirmDelete, onSaveData, isOkToDelete } = props;

    return (
        <div className="immployer__details_form__btns">
            <div className="immployer__details_form__btns__left">
                <button
                    className={`immployer__details_form__delete_record_btn ${isProcessing || !isOkToDelete() ? "immployer__details_form__delete_record_btn__disabled" : ""}`}
                    onClick={(e) => onConfirmDelete(e)}
                >
                    <SVGIcon src={Icons.Trash2Fill} color='grey' />
                    <SVGIcon src={Icons.Trash2Fill} color='error' />
                    <span>Delete {docType}</span>
                </button>
            </div>
            <div className='immployer__details_form__btns__right'>
                <button
                    className={`immployer__btn immployer__btn__secondary ${!isDataChanged() ? "immployer__btn__secondary__disabled" : ""}`}
                    onClick={onDiscardChanges}
                >
                    Discard Changes
                </button>

                <button
                    className={`immployer__btn immployer__btn__primary ${isDisabled('save') ? "immployer__btn__primary__disabled" : ""}`}
                    onClick={onSaveData}
                >
                    {
                        isProcessing
                            ? <i className='immployer__btn_loader' />
                            : 'Save'
                    }
                </button>
            </div>
        </div>
    )
}

export default ImmigrationFormAction;