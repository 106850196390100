import React from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

import MainLayout from "../layouts/Main";
import GettingStarted from "../sub_components/GettingStarted";
import EmployersListTable from "../sub_components/tables/EmployersList";
import ExpiringPermits from "../sub_components/ExpiringPermits";
import { NotificationCard } from "../reusables/Cards";
import { SVGIcon } from "../wrappers/SVGIcon";

import { Icons } from "../../data/Assets";

import useGetOrganizationStatus from "../../hooks/organizations/useGetOrganizationStatus";
import useGetNotifications from "../../hooks/notifications/useGetNotifications";

import { getLMIAApplicable } from "../../store/slices/global";

const HomePage = () => {
    const navigate = useNavigate();

    const LMIAApplicable = useSelector(getLMIAApplicable());

    const { data: organizationStatus } = useGetOrganizationStatus();
    const { data: notifications, isLoading: isNotificationsLoading } = useGetNotifications('organization', undefined);

    const isGettingStartedVisible = () => {
        if (organizationStatus) {
            if (organizationStatus.ctEmployers && organizationStatus.ctLocations && (organizationStatus.ctLMIAs || !LMIAApplicable) && organizationStatus.ctWorkers) {
                return false;
            } else {
                return true;
            };
        } else {
            return false;
        };
    };

    return (
        <MainLayout>
            <div className="immployer__home__page_view">
                <h2 className="color_dark">{isGettingStartedVisible() ? 'Getting Started' : 'Overview'}</h2>
                <br /><br />

                {!organizationStatus
                    ? null
                    : isGettingStartedVisible()
                        ? <GettingStarted
                            employerId={organizationStatus.employerId}
                            ctEmployers={organizationStatus.ctEmployers}
                            ctLocations={organizationStatus.ctLocations}
                            ctLMIAs={organizationStatus.ctLMIAs}
                            ctWorkers={organizationStatus.ctWorkers}
                        />
                        : <div className="immployer__split_views">
                            <div className="immployer__split_notifications_view">
                                <p className="immployer__split_notifications_view__title">
                                    Notifications ({notifications?.length ?? 0})
                                </p>
                                <div className="immployer__split_notifications_view__list">
                                    {
                                        isNotificationsLoading
                                            ? <div className="display_flex flex_dir_column display_flex_center">
                                                <br /><br />
                                                <span className="wt_600 pb_10">Loading</span>
                                                <i className='immployer__list_loader' />
                                            </div>
                                            : !notifications?.length
                                                ? <div className="display_flex flex_dir_column display_flex_center">
                                                    <br /><br />
                                                    <span className="wt_600 pb_10">No new notifications.</span>
                                                </div>
                                                : notifications.map((notification, index) =>
                                                    <NotificationCard
                                                        id={notification.id}
                                                        key={index}
                                                        title={notification.title}
                                                        descr={notification.description}
                                                        date={notification.date}
                                                        actions={notification.actions ?? []}
                                                        criticalAction="Action Required"
                                                        isRead={notification.read}
                                                    />
                                                )
                                    }
                                </div>
                            </div>
                            <div className="immployer__split_employers_list_view">
                                <ExpiringPermits />
                                <div className="immployer__lists__top_section">
                                    <button
                                        onClick={() => navigate('/employers/new')}
                                        className="immployer__btn immployer__btn__primary"
                                    >
                                        <SVGIcon src={Icons.Plus} color='white' />
                                        <span>Add&nbsp;Employer</span>
                                    </button>
                                </div>
                                <EmployersListTable />
                            </div>
                        </div>
                }
            </div>
        </MainLayout>
    );
};

export default HomePage;