import React, { useEffect } from 'react';

import { IToast } from '../../interfaces/toast';

import useValues from '../../hooks/useValues';
import useToast from '../../hooks/useToast';

import { SVGIcon } from '../wrappers/SVGIcon';

import { Icons } from '../../data/Assets';

const Toast = (props: { toast: IToast }) => {
    const { id, type, title, message, timeout } = props.toast;

    const { removeToast } = useToast();

    useEffect(() => {
        const timeoutTime = timeout ?? 4000;

        const timer = setTimeout(() => {
            removeToast(id);
        }, timeoutTime);

        return () => clearTimeout(timer);

        // eslint-disable-next-line
    }, [id]);

    return (
        <div className={`immployer__toast immployer__toast__${type}`}>
            <div className="immployer__toast_icon">
                {type === 'error' && <SVGIcon src={Icons.ExclamationCircleFill} color='error' />}
                {type === 'success' && <SVGIcon src={Icons.CheckCircleFill} color='success' />}
                {type === 'info' && <SVGIcon src={Icons.InfoCircleFill} color='primary' />}
            </div>
            <div className='immployer__toast_content'>
                <h5 className='color_dark'>{title}</h5>
                {!!message && <p className='mice'>{message}</p>}
            </div>
            {<button className='immployer__toast__close_btn' onClick={() => removeToast(id)}><SVGIcon src={Icons.X} /></button>}
        </div>
    );
};

export const Toasts = () => {
    const { toasts } = useValues();

    return (
        <div className='immployer__toasts_container'>
            {toasts.map(toast =>
                <Toast key={toast.id} toast={toast} />
            )}
        </div>
    );
};

export default Toasts;