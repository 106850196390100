import React, { ImgHTMLAttributes } from 'react';

import { useGetSignedUrl } from '../../hooks/misc';

export const ImageAsset = (props: ImgHTMLAttributes<HTMLImageElement>) => {
    const { src = null, alt = '', ...rest } = props;

    const { data: signedUrl } = useGetSignedUrl(src);

    return <img src={signedUrl ?? ''} alt={alt} {...rest} />;
};