import React from 'react'

import { SVGIcon } from '../wrappers/SVGIcon';

interface IToolTipProps {
    icon: string;
    message: string;
}
const ToolTip = (props: IToolTipProps) => {
    const { icon, message } = props

    return (
        <div className='immployer__tooltip'>
            <span className='immployer__tooltip__message'>
                {message}
            </span>
            <div className='immployer__tooltip__icon'>
                <SVGIcon src={icon} />
            </div>
        </div>
    );
}

export default ToolTip