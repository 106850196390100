import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { isEqual } from 'lodash';
import moment from 'moment';

import useSaveWorkerJobs from '../../../hooks/worker_jobs/useSaveWorkerJobs';

import { closeModal, openModal } from '../../../store/slices/modal';

import { getCanadianDollarsString, getDateString } from '../../../helpers/utility';
import validationHelper from '../../../helpers/validators';

import { OPEmployementStatuses, OPJobTypes, OPWageTypes } from '../../../data/options';
import { Icons } from '../../../data/Assets';

import { IJobTitle, ILocation, IWorkerJob } from '../../../interfaces/entities';
import { IWorkerEmploymentData, WorkerEmploymentField } from '../../../interfaces/foreign_worker';
import { ISelectDropdownOption } from '../../../interfaces/formElements';
import { IWorkerJobToSave } from '../../../interfaces/services';
import { TEmployementStatus, TJobType, TWageType } from '../../../interfaces/entities.types';

import ToolTip from '../../reusables/ToolTip';
import { CalenderInput, SearchSelect, SelectDropdown, TextInput } from '../../reusables/FormElements';
import { SVGIcon } from '../../wrappers/SVGIcon';

interface IEmploymentFormActionsProps {
    index: number;
    length: number;
    workerJob: IWorkerJob | null;
    jobTitles: IJobTitle[];
    locations: ILocation[];
    isLoading: boolean;
    hasUnsavedData: { [key: number]: boolean };
    setActiveJobs: React.Dispatch<React.SetStateAction<{ [key: number]: boolean }>>;
    setJobDurations: React.Dispatch<React.SetStateAction<{ [key: number]: [string, string] }>>;
    setWorkerJobDatas: React.Dispatch<React.SetStateAction<(IWorkerJob | null)[]>>;
    setIsGlobalLoading: React.Dispatch<React.SetStateAction<{ [key: number]: boolean }>>;
    setHasUnsavedData: React.Dispatch<React.SetStateAction<{ [key: number]: boolean }>>;
}

const EmploymentForm = (props: IEmploymentFormActionsProps) => {
    const { index, length, workerJob, jobTitles, locations, isLoading, hasUnsavedData, setActiveJobs, setJobDurations, setWorkerJobDatas, setIsGlobalLoading, setHasUnsavedData } = props;

    const { worker_id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const requiredFields: WorkerEmploymentField[] = ["jobTitle", "type", "location", "employmentStatus"];
    const selectFields: WorkerEmploymentField[] = ["jobTitle", "type", "location", "wageType", "employmentStatus"];

    const initialFormData: IWorkerEmploymentData = {
        id: null,
        jobTitle: { value: { value: '', label: '' }, error: "" },
        type: { value: { value: "", label: "" }, error: "" },
        employeeNumber: { value: "", error: "" },
        location: { value: { value: "", label: "" }, error: "" },
        startDate: { value: "", error: "" },
        endDate: { value: "", error: "" },
        wage: { value: "", error: "" },
        wageType: { value: { value: "", label: "" }, error: "" },
        workEmail: { value: "", error: "" },
        employmentStatus: { value: { value: "", label: "" }, error: "" },
    };

    const [formData, setFormData] = useState<IWorkerEmploymentData>(initialFormData);
    const [isProcessing, setIsProcessing] = useState(false);

    const { mutate: saveWorkerJobs } = useSaveWorkerJobs();

    const hasGlobalUnsavedChanges = () => {
        return Object.entries(hasUnsavedData).some(([key, value]) => Number(key) !== index && value);
    }

    const isDisabled = (reason: string | null) => {
        const emptyFields = requiredFields.filter((field) => {
            if (selectFields.includes(field)) {
                return !(formData[field].value as ISelectDropdownOption).value?.trim()?.length;
            } else {
                return !(formData[field].value as string)?.trim()?.length;
            }
        });

        const commonDisabled = !worker_id || isProcessing || isLoading || hasGlobalUnsavedChanges();

        if (reason === "save") {
            return commonDisabled || !isDataChanged() || emptyFields.length > 0;
        }

        return commonDisabled;
    }

    const getFormData = (): IWorkerEmploymentData => {
        if (workerJob) {
            const workerJobDetail = workerJob.worker_job_details[0];
            const job = workerJob.job;
            const jobDetail = job.job_details[0];

            const jobTitle: IJobTitle | undefined = jobTitles.find((jobTitle) => jobTitle.job_title === jobDetail.title);
            const location: ILocation | undefined = locations.find((location) => location.id === job.location_id);
            const jobType = OPJobTypes.find(option => option.value === jobDetail.type);
            const wageType = OPWageTypes.find(option => option.value === workerJobDetail.wage_type);
            const employmentStatus = OPEmployementStatuses.find(option => option.value === workerJobDetail.status);
            const wage = workerJobDetail.wage ? getCanadianDollarsString(workerJobDetail.wage.toString()) : "";
            const startDate = workerJobDetail.start_date ? getDateString(workerJobDetail.start_date) : "";
            const endDate = workerJobDetail.end_date ? getDateString(workerJobDetail.end_date) : "";

            return {
                id: workerJob.id,
                jobTitle: { value: { value: jobTitle?.job_title ?? '', label: jobTitle ? `${jobTitle.noc_code.noc_code} - ${jobTitle.job_title}` : '' }, error: "" },
                type: { value: { value: jobType?.value ?? '', label: jobType?.label ?? '' }, error: "" },
                employeeNumber: { value: workerJobDetail.employee_number ?? '', error: "" },
                location: { value: { value: location?.id?.toString() ?? '', label: location ? `${location.identifier} - ${location.employer.legal_name}` : '' }, error: "" },
                startDate: { value: startDate ?? '', error: "" },
                endDate: { value: endDate ?? '', error: "" },
                wage: { value: wage, error: "" },
                wageType: { value: { value: wageType?.value ?? '', label: wageType?.label ?? '' }, error: "" },
                workEmail: { value: workerJobDetail.email ?? '', error: "" },
                employmentStatus: { value: { value: employmentStatus?.value ?? '', label: employmentStatus?.label ?? '' }, error: "" },
            };
        } else {
            return initialFormData;
        }
    }

    const isDataChanged = () => {
        const originalData = getFormData();
        return !isEqual(originalData, formData);
    }

    const onDiscardChanges = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.preventDefault();
        if (isDataChanged() && !hasGlobalUnsavedChanges())
            setFormData(getFormData());
    }

    const onChangeText = (name: WorkerEmploymentField, value: string) => {
        setFormData({ ...formData, [name]: { value, error: "" } });
    }

    const onChangeSelect = (name: WorkerEmploymentField, option: ISelectDropdownOption) => {
        setFormData({ ...formData, [name]: { value: option, error: "" } });
    }

    const onDeleteWorkerJob = async () => {
        if (!worker_id) return;

        // if (formData.id) {
        // delete worker job hook
        setIsGlobalLoading({ [index]: false });
        setHasUnsavedData({ [index]: false });
        setWorkerJobDatas((prevWorkerJobs) => prevWorkerJobs.filter((_, i) => i !== index));
        // } else {
        // setIsGlobalLoading({ [index]: false });
        // setHasUnsavedData({ [index]: false });
        // setWorkerJobDatas((prevWorkerJobs) => prevWorkerJobs.filter((_, i) => i !== index));
        // }
    }

    const isOkToDelete = () => {
        return false;

        // if (isProcessing || hasGlobalUnsavedChanges()) return false;

        // return Object.entries(formData).some(([key, value]) => {
        //     if (key === "id") return false;
        //     if (selectFields.includes(key as WorkerEmploymentField)) {
        //         return (value.value as ISelectDropdownOption).value?.trim()?.length > 0;
        //     } else {
        //         return (value.value as string)?.trim()?.length > 0;
        //     }
        // });
    };

    const onConfirmDelete = async (e: React.MouseEvent) => {
        e.preventDefault();

        if (isProcessing || !isOkToDelete()) return;

        if (formData.id) {
            if (length < 2) {
                dispatch(openModal({
                    name: 'MESSAGE_MODAL',
                    data: {
                        view: 'WARNING',
                        closeBtn: true,
                        title: "Cannot Delete!",
                        subtitle: "Atleast one job is required for a worker.",
                        content: ["Please add another job before deleting this one."],
                        cancelTxt: "Okay",
                        cancelFn: () => dispatch(closeModal('MESSAGE_MODAL')),
                    }
                }));
                return;
            }
        }

        dispatch(openModal({
            name: 'MESSAGE_MODAL',
            data: {
                view: 'CONFIRMATION',
                closeBtn: true,
                title: "Confirm Delete?",
                content: "Are you sure you want to delete this job?",
                actionTxt: "Confirm",
                action: async () => {
                    await onDeleteWorkerJob();
                    dispatch(closeModal('MESSAGE_MODAL'));
                }
            }
        }));
    }

    const onSaveData = () => {
        if (isDisabled("save")) return;

        setFormData((prevData) => {
            return Object.keys(prevData).reduce((acc, key) => {
                if (key === "id") return { ...acc, id: prevData.id };
                return { ...acc, [key]: { value: prevData[key as WorkerEmploymentField].value, error: "" } };

            }, {} as IWorkerEmploymentData);
        });

        setIsProcessing(true);

        let isEmailValid = true;
        if (formData.workEmail.value) {

            const emailValError = validationHelper.validateInput("email", formData.workEmail.value);

            if (emailValError) {
                isEmailValid = false;
                setFormData((prevData) => {
                    return { ...prevData, workEmail: { value: prevData.workEmail.value, error: emailValError } };
                });
            }
        }

        if (!isEmailValid) {
            setIsProcessing(false);
            return;
        };

        const jobTitle = jobTitles.find((jobTitle) => jobTitle.job_title === formData.jobTitle.value.value);
        if (!jobTitle) {
            setIsProcessing(false);
            return;
        }

        const workerJob: IWorkerJobToSave = {
            id: formData.id ?? null,
            location_id: parseInt(formData.location.value.value),
            title: formData.jobTitle.value.value,
            noc: jobTitle.noc_code.noc_code,
            type: formData.type.value.value as TJobType,
            start_date: formData.startDate.value?.trim()?.length ? moment.utc(formData.startDate.value).toDate().toISOString() : null,
            end_date: formData.endDate.value?.trim()?.length ? moment.utc(formData.endDate.value).toDate().toISOString() : null,
            wage_type: formData.wageType.value.value ? formData.wageType.value.value as TWageType : null,
            wage: formData.wage.value?.trim()?.length ? parseFloat(formData.wage.value.replace(/[^0-9.]/g, '')) : null,
            status: formData.employmentStatus.value.value as TEmployementStatus,
            employee_number: formData.employeeNumber.value?.trim()?.length ? formData.employeeNumber.value : null,
            email: formData.workEmail.value?.trim()?.length ? formData.workEmail.value : null,
        }

        saveWorkerJobs({
            payload: {
                foreignWorkerId: Number(worker_id),
                workerJobs: [workerJob],
            },
            setIsProcessing,
        })
    }

    useEffect(() => {
        const originalData = getFormData();
        setFormData(originalData);
    }, [workerJob]); // eslint-disable-line

    useEffect(() => {
        if (isProcessing || isLoading) {
            setIsGlobalLoading({ [index]: true });
        } else {
            setIsGlobalLoading({ [index]: false });
        }
    }, [isProcessing, isLoading]); // eslint-disable-line

    useEffect(() => {
        if (isDataChanged()) {
            setHasUnsavedData({ [index]: true });
        } else {
            setHasUnsavedData({ [index]: false });
        }

        setActiveJobs((prevActiveJobs) => {
            return { ...prevActiveJobs, [index]: formData.employmentStatus.value.value === "employed" };
        });

        setJobDurations((prevJobDurations) => {
            return { ...prevJobDurations, [index]: [formData.startDate.value, formData.endDate.value] };
        });
    }, [formData, index]) // eslint-disable-line

    return (
        <>
            <div className="immployer__details_form_inputs">
                <SearchSelect
                    name="jobTitle"
                    label="Job Title"
                    required={true}
                    placeholder="Search job title"
                    value={formData.jobTitle.value}
                    onChange={(option: ISelectDropdownOption) => onChangeSelect("jobTitle", option)}
                    errorMsg={formData.jobTitle.error}
                    options={jobTitles.map(jobTitle => ({ value: jobTitle.job_title, label: `${jobTitle.noc_code.noc_code} - ${jobTitle.job_title}` }))}
                    read_only={isLoading || !jobTitles.length || isProcessing || hasGlobalUnsavedChanges()}
                    length_flex={2}
                />
                <SelectDropdown
                    name="type"
                    label="Job Type"
                    required={true}
                    placeholder="-- select job type --"
                    value={formData.type.value}
                    onChange={(option: ISelectDropdownOption) => onChangeSelect("type", option)}
                    errorMsg={formData.type.error}
                    options={OPJobTypes}
                    read_only={isLoading || isProcessing || hasGlobalUnsavedChanges()}
                />
                <TextInput
                    name="employeeNumber"
                    type="text"
                    pattern_mask="doc_number"
                    max_length={45}
                    label="Employee ID"
                    placeholder="Enter Employee ID"
                    value={formData.employeeNumber.value}
                    onChange={(value: string) => onChangeText("employeeNumber", value)}
                    errorMsg={formData.employeeNumber.error}
                    read_only={isLoading || isProcessing || hasGlobalUnsavedChanges()}
                />
                <SelectDropdown
                    name="location"
                    label="Location"
                    required={true}
                    placeholder="-- select location --"
                    value={formData.location.value}
                    onChange={(option: ISelectDropdownOption) => onChangeSelect("location", option)}
                    errorMsg={formData.location.error}
                    options={locations.map((location: ILocation) => ({ value: location.id.toString(), label: `${location.identifier} - ${location.employer.legal_name}` }))}
                    read_only={isLoading || isProcessing || hasGlobalUnsavedChanges()}
                    addNewBtnData={{
                        name: "Location",
                        label: "Add Location",
                        action: () => navigate(`/employers`),
                    }}
                />
                <CalenderInput
                    name="startDate"
                    label="Start Date"
                    required={false}
                    errorMsg={formData.startDate.error}
                    placeholder="mm/dd/yyyy"
                    format="mm/dd/yyyy"
                    tooltip={<ToolTip icon={Icons.InfoCircle} message="Select the date you started or will start your employment (format: MM/DD/YYYY)." />}
                    onChange={(value: string) => onChangeText("startDate", value)}
                    value={formData.startDate.value}
                    read_only={isLoading || isProcessing || hasGlobalUnsavedChanges()}
                />
                <CalenderInput
                    name="endDate"
                    label="End Date"
                    errorMsg={formData.endDate.error}
                    placeholder="mm/dd/yyyy"
                    format="mm/dd/yyyy"
                    onChange={(value: string) => onChangeText("endDate", value)}
                    value={formData.endDate.value}
                    read_only={isLoading || isProcessing || hasGlobalUnsavedChanges()}
                />
                <SelectDropdown
                    name="wageType"
                    label="Wage Type"
                    required={false}
                    placeholder="-- select wage type --"
                    value={formData.wageType.value}
                    onChange={(option: ISelectDropdownOption) => onChangeSelect("wageType", option)}
                    errorMsg={formData.wageType.error}
                    options={OPWageTypes}
                    read_only={isLoading || isProcessing || hasGlobalUnsavedChanges()}
                />
                <TextInput
                    name="wage"
                    type="text"
                    pattern_mask="currency_cad"
                    label="Wage"
                    required={false}
                    placeholder="Enter wage"
                    value={formData.wage.value}
                    onChange={(value: string) => onChangeText("wage", value)}
                    errorMsg={formData.wage.error}
                    read_only={isLoading || isProcessing || hasGlobalUnsavedChanges()}
                />
                <TextInput
                    name="workEmail"
                    type="email"
                    label="Work Email"
                    max_length={100}
                    placeholder="Enter work email"
                    value={formData.workEmail.value}
                    onChange={(value: string) => onChangeText("workEmail", value)}
                    errorMsg={formData.workEmail.error}
                    read_only={isLoading || isProcessing || hasGlobalUnsavedChanges()}
                />
                <SelectDropdown
                    name="employmentStatus"
                    label="Employment Status"
                    required={true}
                    placeholder="-- select status --"
                    value={formData.employmentStatus.value}
                    onChange={(option: ISelectDropdownOption) => onChangeSelect("employmentStatus", option)}
                    errorMsg={formData.employmentStatus.error}
                    options={OPEmployementStatuses}
                    read_only={isLoading || isProcessing || hasGlobalUnsavedChanges()}
                />
            </div>
            <div className="immployer__details_form__btns">
                <div className="immployer__details_form__btns__left">
                    <button
                        className={`immployer__details_form__delete_record_btn ${isProcessing || !isOkToDelete() ? "immployer__details_form__delete_record_btn__disabled" : ""}`}
                        onClick={(e) => onConfirmDelete(e)}
                    >
                        <SVGIcon src={Icons.Trash2Fill} color='grey' />
                        <SVGIcon src={Icons.Trash2Fill} color='error' />
                        <span>Delete</span>
                    </button>
                </div>
                <div className='immployer__details_form__btns__right'>
                    <button
                        className={`immployer__btn immployer__btn__secondary ${!isDataChanged() ? "immployer__btn__secondary__disabled" : ""}`}
                        onClick={onDiscardChanges}
                    >
                        Discard Changes
                    </button>

                    <button
                        className={`immployer__btn immployer__btn__primary ${isDisabled('save') ? "immployer__btn__primary__disabled" : ""}`}
                        onClick={onSaveData}
                    >
                        {
                            isProcessing
                                ? <i className='immployer__btn_loader' />
                                : 'Save'
                        }
                    </button>
                </div>
            </div>

        </>
    )
};
export default EmploymentForm; 